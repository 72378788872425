import { EmailType } from "../types/EmailType"
import axios from "axios"
import { SetAuthToken } from "./helperService"
import { config } from "../Utils/baseUrl";

var baseUrl = `${config.url.API_URL}/emails`

export async function sendEmail(emailObject: EmailType) {
    SetAuthToken(axios)
    await axios.post(baseUrl, emailObject, { withCredentials: true })
}