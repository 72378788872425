import { UpdateUserInfo } from "../../services/userService";
import { UserType } from "../../types/UserType"
import { Chip, Input, ThemeProvider } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import CancelIcon from '@mui/icons-material/Cancel';
import { StyledTextField, Theme } from '../shared/styledTextField';
import Styles from "../../css/profile/editProfile.module.css";
import { bioDescriptionMaxChars } from "../../Utils/valueSettings";
interface EditProfileInterface {
    showModal: boolean;
    onClose: () => void;
    user: UserType
}
const EditProfileModal: React.FC<EditProfileInterface> = ({ showModal, onClose, user }) => {
    const [updatedUser, setUpdatedUser] = useState<UserType>(user)
    const [skills, setSkills] = useState<string[]>(user.skills! ?? [])
    const [skill, setSkill] = useState("")
    const [skillError, setSkillError] = useState(false)
    const [loading, setLoading] = useState(false)
    const addSkill = () => {
        if (skills.length < 5) {
            if (skill.trim() !== "" && !skills.includes(skill)) {
                const newSkills = [...skills, skill]
                setSkills(newSkills)
                const updatedUserCopy = { ...updatedUser };
                updatedUserCopy.skills = newSkills
                setUpdatedUser(updatedUserCopy);
                setSkill("")
            } 
        }else {
            setSkillError(true);
            setTimeout(() => setSkillError(false), 3000);
        }
    }

    
    const removeSkill = (index: number) => {
        const newData = [...skills];
        newData.splice(index, 1);
        setSkills(newData)
        const updatedUserCopy = { ...updatedUser };
        updatedUserCopy.skills = newData
        setUpdatedUser(updatedUserCopy);
    }
    const handleSubmit = async () => {
        setLoading(true)
        await UpdateUserInfo(updatedUser)
        onClose()
        window.location.href = window.location.href
        setLoading(false)
    }
    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64String = reader.result;
                const updatedUserCopy = { ...updatedUser };
                updatedUserCopy.profilePicture = base64String?.toString()
                setUpdatedUser(updatedUserCopy);
            };
        }
    };
    return (
        <>
            <Modal  show={showModal} onHide={() => { onClose() }}>
                <Modal.Header closeButton style={{backgroundColor: "#2A2D34"}}>
                    <Modal.Title className={Styles.editProfileText}>Edit Profile</Modal.Title>
                </Modal.Header>
                {user && <Modal.Body style={{backgroundColor: "#2A2D34"}}>
                <ThemeProvider theme={Theme}>
                        <Form.Group controlId="message">
                            <Form.Label className={Styles.editProfileText}>Job Title</Form.Label>
                            <StyledTextField fullWidth id="standard-basic" variant="standard" inputProps={{maxLength: 40}} value={updatedUser.jobTitle} onChange={(e) => {
                                const updatedUserCopy = { ...updatedUser };
                                updatedUserCopy.jobTitle = e.target.value;
                                setUpdatedUser(updatedUserCopy);
                            }} />
                            <Form.Label className={Styles.editProfileText}>Location</Form.Label>
                            <StyledTextField fullWidth id="standard-basic" variant="standard" inputProps={{maxLength: 20}}
                            value={updatedUser.location} onChange={(e) => {
                                const updatedUserCopy = { ...updatedUser };
                                updatedUserCopy.location = e.target.value
                                setUpdatedUser(updatedUserCopy)
                            }} />
                            <Form.Label className={Styles.editProfileText}>Bio</Form.Label>
                            <Form.Control as="textarea" rows={5} value={updatedUser.description} maxLength={bioDescriptionMaxChars} onChange={(e) => {
                                const updatedUserCopy = { ...updatedUser };
                                updatedUserCopy.description = e.target.value
                                setUpdatedUser(updatedUserCopy)
                            }} />
                            {updatedUser.description != null && <p style={{textAlign: "right", color: (updatedUser.description?.length < bioDescriptionMaxChars) ? "white" : "red"}}>{updatedUser.description?.length}/{bioDescriptionMaxChars}</p>}
                            <Form.Label className={Styles.editProfileText}>Edit Skills</Form.Label>
                            <br/>
                            {skills?.length > 0 && skills.map((element, index) => {
                                return <><Chip key={index} className={Styles.skillChip}label={element} deleteIcon={<CancelIcon />} onDelete={(e) => { removeSkill(index) }} /></>
                            })}
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                                addSkill()
                            }}>
                                <div />
                                <StyledTextField fullWidth error={skillError} helperText={skillError ? "Skill limit reached" : ""}
                                    id="standard-basic" label="Skills" variant="standard" value={skill} inputProps={{maxLength: 20}}
                                    name="skill" onChange={(e) => { setSkill(e.target.value) }} />
                                <Button id={Styles.addSkillButton} type="submit" >add skill</Button>
                                
                            </Form>
                            <Form.Label className={Styles.editProfileText}>LinkedIn</Form.Label><br/>
                            <StyledTextField style={{width: "45%", marginRight: "1%"}} id="standard-basic" disabled={true} variant="standard"  value={"https://www.linkedin.com/in/"} />
                            <StyledTextField style={{width: "45%"}} id="standard-basic" variant="standard" placeholder="LinkedIn username"
                                inputProps={{maxLength: 30}} value={updatedUser.linkedin} onChange={(e) => {
                                const updatedUserCopy = { ...updatedUser };
                                updatedUserCopy.linkedin = e.target.value
                                setUpdatedUser(updatedUserCopy)
                            }} />
                            <br/>
                            <Form.Label className={Styles.editProfileText}>Instagram</Form.Label><br/>
                            <StyledTextField style={{width: "45%", marginRight: "1%"}} id="standard-basic" disabled={true} variant="standard" placeholder="Instagram username"
                            value={"https://www.instagram.com/"} />
                            <StyledTextField id="standard-basic" variant="standard" inputProps={{maxLength: 30}} value={updatedUser.instagram} 
                            placeholder="Instagram username"
                            onChange={(e) => {
                                const updatedUserCopy = { ...updatedUser };
                                updatedUserCopy.instagram = e.target.value
                                setUpdatedUser(updatedUserCopy)
                            }} />
                            <br/>
                            <Form.Label className={Styles.editProfileText}>Facebook</Form.Label><br/>
                            <StyledTextField style={{width: "45%", marginRight: "1%"}} id="standard-basic" disabled={true} variant="standard" 
                             value={"https://www.facebook.com/"} />
                            <StyledTextField id="standard-basic" variant="standard" inputProps={{maxLength: 30}} value={updatedUser.facebook} 
                            placeholder="Facebook username"
                            onChange={(e) => {
                                const updatedUserCopy = { ...updatedUser };
                                updatedUserCopy.facebook = e.target.value
                                setUpdatedUser(updatedUserCopy)
                            }} /><br/>
                            <Form.Label className={Styles.editProfileText}>Looking for work</Form.Label>
                            <br />
                            <Form.Check style={{color: "white"}} type="checkbox" label="I am Looking for work" checked={updatedUser.isLookingForWork!} onChange={() => {
                                const updatedUserCopy = { ...updatedUser };
                                updatedUserCopy.isLookingForWork = !updatedUser.isLookingForWork!
                                setUpdatedUser(updatedUserCopy)
                            }} />
                            <Form.Label className={Styles.editProfileText}>Update Profile Picture</Form.Label>
                            <br />
                            <Input
                                type="file"
                                onChange={handleImageChange}
                            />
                            <div className={Styles.seperator} />
                            
                        </Form.Group>
                        <Button id={Styles.submitButton} onClick={handleSubmit}>{loading ? <Spinner /> : <>Submit</>}</Button>
                        <Button id={Styles.cancelButton} onClick={onClose}>Cancel</Button>
                    </ThemeProvider>
                </Modal.Body>}
                {user == null && <div style={{ margin: "0 auto" }}><Spinner /></div>}
            </Modal>
        </>
    )
}
export default EditProfileModal