import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { PaymentStateEnum, SubmissionType } from '../../types/SubmissionType';
interface SubmissionsCustomAccordionProps {
    submission: SubmissionType;
}
const SubmissionsCustomAccordion: React.FC<SubmissionsCustomAccordionProps> = ({ submission }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const getStatus = () => {
        switch (submission.paymentState) {
            case PaymentStateEnum.Unpaid:
                return <span style={{color: "red"}}>- Navigate to <a href="/profile/dashboard/submissions" style={{color: "blue", textDecoration: "underline"}}>Manage Submissions</a> and upload files, then await approval from customer</span>
            case PaymentStateEnum.Pending:
                return <span style={{color: "purple"}}>- Payment Pending</span>
            case PaymentStateEnum.Paid:
                return <span style={{color: "green"}}>- Payment Complete</span>
            default:
                return <span style={{color: "grey"}}>- Payment Status Unknown</span>
        }
    }
    return (
        <div>
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', maxWidth: "33%", flexShrink: 0, overflow: "hidden", textOverflow: "ellipsis" }}>
                        Job Title: {submission.cardTitle}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        {submission.paymentState != PaymentStateEnum.Paid && (
                            <>
                                <p style={{ display: "inline" }}>
                                    {submission.isAccepted ? (
                                        <p style={{ display: "inline", color: "#009B72" }}>Submission Accepted {getStatus()}</p>
                                    ) : submission.isRejected ? (
                                        <span style={{ color: "red" }}>Submission Rejected</span>
                                    ) : (
                                        <p style={{ display: "inline", color: "#009DDC" }}>Pending</p>
                                    )}
                                </p>
                            </>
                        )}
                        {submission.paymentState == PaymentStateEnum.Paid && (
                            <p style={{ display: "inline", color: "#009B72" }}>Payment Completed</p>
                        )}
                    </Typography>
                </AccordionSummary>
                {submission.paymentState == PaymentStateEnum.Unpaid && (
                    <AccordionDetails>
                        <Typography>
                            <div style={{ margin: "1%" }}>
                                <a href={`/job/${submission.cardId}`}>View Job</a> • <a href={submission.link}>{submission.link}</a> • {submission.isAccepted ? (
                                    <span style={{ color: "green" }}>Accepted</span>
                                ) : submission.isRejected ? (
                                    <span style={{ color: "red" }}>Rejected</span>
                                ) : (
                                    <span style={{ color: "purple" }}>Pending</span>
                                )}
                            </div>
                        </Typography>
                    </AccordionDetails>
                )}
            </Accordion>
        </div>
    );
}
export default SubmissionsCustomAccordion;