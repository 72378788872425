import CurrentUserContext from "../../client/UserContextProvider";
import { sendEmail } from "../../services/emailService";
import { rejectSubmission } from "../../services/submissionService";
import { CardType } from "../../types/CardType";
import { EmailType } from "../../types/EmailType";
import { SubmissionType } from "../../types/SubmissionType";
import { useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Styles from "../../css/profile/dashboard.module.css";
import { RejectSubmissionModalText } from "../../Text/emails";

interface SubmissionModalProps {
    showModal: boolean;
    onClose: () => void;
    submissionDetails: SubmissionType;
    cardDetails: CardType;
}
const RejectSubmissionModal: React.FC<SubmissionModalProps> = ({ showModal, onClose, submissionDetails, cardDetails }) => {
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        const emailData: EmailType = {
            from: "buildmyidea.contact@gmail.com",
            to: submissionDetails.posterEmail,
            message: RejectSubmissionModalText(cardDetails.jobTitle!, submissionDetails.link),
            subject: `Submission Update: Rejected for ${cardDetails.jobTitle}`,
            sendersName: "Build-My-Idea Support"
        }

        onClose()
        var i: SubmissionType = cardDetails.submissions.find(x => x.id == submissionDetails.id)!
        i.isRejected = true
        await rejectSubmission(submissionDetails)
        await sendEmail(emailData);
    }
    return (
        <Modal show={showModal} onHide={() => {
            onClose()
        }}>
            <Modal.Header closeButton style={{backgroundColor: "#2A2D34", color: "white"}}>
                <Modal.Title>Reject a submission</Modal.Title>

            </Modal.Header>
            <Modal.Body style={{backgroundColor: "#2A2D34", color: "white"}}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="message">
                        <Form.Label>Once this submission is rejected you cannot undo this action. Are you sure you want to continue?</Form.Label>
                    </Form.Group>
                    <Button className={Styles.rejectAgreeButton} type="submit">Yes</Button>
                    <Button className={Styles.rejectDisagreeButton} onClick={onClose}>No</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default RejectSubmissionModal;