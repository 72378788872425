import Styles from '../../css/home/searchBar.module.css'
import { Checkbox, Chip, Collapse, FormControlLabel, FormGroup, List, ListItemButton, ListItemText } from '@mui/material';
import { TechnologyTags, WebFrameworkTags } from '../../types/TechnologyTagsEnum';
import { FilterType } from '../../types/FilterType';
import { useEffect, useState } from 'react';
import { Button, Container, Form, InputGroup } from 'react-bootstrap';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import Slider from '@mui/material/Slider';

interface FilterBoxProps {
    setFilterTags: (newValue: string[]) => void,
    filters: FilterType[],
    setFilters: (newValue: FilterType[]) => void,
    setRange: (value: string) => void,
    maxBounty: number;
}

interface OpenState {
    webTechnologies: boolean;
    webFrameworks: boolean;
    customTag: boolean;
}

const FilterBox: React.FC<FilterBoxProps> = ({ setFilterTags, filters, setFilters, setRange, maxBounty }) => {
    const [bountyRange, setBountyRange] = useState<number[]>([0, maxBounty])
    const [customTag, setCustomTag] = useState('');
    const [tempRange, setTempRange] = useState<number[]>([0, maxBounty])
    const [open, setOpen] = useState({
        webTechnologies: false,
        webFrameworks: false,
        customTag: false,
    });
    const [checkedStates, setCheckedStates] = useState<{
        webTechnologies: string[],
        webFrameworks: string[],
    }>({
        webTechnologies: [],
        webFrameworks: [],
    });
    useEffect(() => {
        setBountyRange([0, maxBounty])
        setTempRange([0, maxBounty])
    }, [maxBounty])
    const handleClick = (section: keyof OpenState) => {
        setOpen((prevOpen) => ({ ...prevOpen, [section]: !prevOpen[section] }));
    };

    const addFilter = (index: number, choice: Object = {}, custom: string = "") => {
        let i: FilterType;
        if (custom) {
            i = { Type: { [custom]: "#6761A8" }, Index: 0 };
        } else {
            i = { Type: choice, Index: index };
        }
        const newFilterList = [...filters, i];
        setFilters(newFilterList);
        updateFilterTags(newFilterList);
    };

    const removeFilter = (element: string) => {
        var newFilterList = filters.filter((filter) => Object.keys(filter.Type)[filter.Index] !== element)
        updateFilterTags(newFilterList)
        setFilters(newFilterList)

        setCheckedStates((prevCheckedStates) => ({
            webTechnologies: prevCheckedStates.webTechnologies.filter((e) => e !== element),
            webFrameworks: prevCheckedStates.webFrameworks.filter((e) => e !== element),
        }));
    }
    const updateFilterTags = (newFilterList: FilterType[]) => {
        var listOfTags: string[] = newFilterList.map(filter => {
            return Object.keys(filter.Type)[filter.Index]
        })
        setFilterTags(listOfTags)
    }

    const handleCustomTagSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (customTag) {
            addFilter(0, {}, customTag);
            setCustomTag("");
        }
    };

    const handleClear = () => {
        setFilterTags([]);
        setFilters([]);
        setRange("")
        setBountyRange([0, maxBounty])
        setTempRange([0, maxBounty])

        setCheckedStates({
            webTechnologies: [],
            webFrameworks: [],
        });
    }

    const handleCheckboxChange = (checked: boolean, index: number, choice: Object, section: string) => {
        const element = Object.keys(choice)[index];
        let updatedCheckedStates: string[];

        if (section === 'webTechnologies') {
            updatedCheckedStates = checked
                ? [...checkedStates.webTechnologies, element]
                : checkedStates.webTechnologies.filter(e => e !== element);
        } else {
            updatedCheckedStates = checked
                ? [...checkedStates.webFrameworks, element]
                : checkedStates.webFrameworks.filter(e => e !== element);
        }

        setCheckedStates({
            ...checkedStates,
            [section]: updatedCheckedStates,
        });

        if (checked) {
            addFilter(index, choice);
        } else {
            removeFilter(element);
        }
    };
    const handleBountyRangeChange = (event: Event, newValue: number | number[]) => {
        setTempRange(newValue as number[]);
    };

    const handleBountyRangeCommit = (event: Event | React.SyntheticEvent, newValue: number | number[]) => {
        setRange(`${(newValue as number[])[0]}-${(newValue as number[])[1]}`);
    };
    return (
        <Container id={Styles.filterWrapper}>
            <div style={{padding: "20px", backgroundColor: "#2A2D34"}}>
                <div style={{ color: "white" }}>
                    <FilterListOutlinedIcon /> Filters: {filters.map((filter, index) =>
                        <Chip key={index}
                            onDelete={() => removeFilter(Object.keys(filter.Type)[filter.Index])}
                            onClick={() => removeFilter(Object.keys(filter.Type)[filter.Index])}
                            label={Object.keys(filter.Type)[filter.Index]}
                            style={{ backgroundColor: Object.values(filter.Type)[filter.Index], cursor: "pointer", margin: "0.5%" }} />)}
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <p style={{ color: "white" }}><span style={{ color: "red" }}>Bounty Range:</span> ${Intl.NumberFormat().format(bountyRange[0])} - ${Intl.NumberFormat().format(bountyRange[1])}</p>
                </div>
                <Slider
                    value={tempRange}
                    onChange={handleBountyRangeChange}
                    valueLabelDisplay="auto"
                    onChangeCommitted={handleBountyRangeCommit}
                    min={0}
                    max={maxBounty}
                    valueLabelFormat={(value) => `$${Intl.NumberFormat().format(value)}`}
                />
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: '#2A2D34' }}
                    component="nav">
                    <ListItemButton onClick={() => handleClick('webTechnologies')} >
                        <ListItemText primary="Web Technologies" style={{ color: 'white' }} />
                        {open.webTechnologies ? <ExpandLess sx={{color: "white"}}/> : <ExpandMore sx={{color: "white"}}/>}
                    </ListItemButton>
                    <Collapse in={open.webTechnologies} timeout="auto" unmountOnExit>
                        <List component="div" className={Styles.technologyGroup} sx={{ maxHeight: 200, overflow: 'auto', color: 'grey.400' }}>
                            <FormGroup>
                                {Object.keys(TechnologyTags).map((element, index) => (
                                    <FormControlLabel
                                        key={`technology=${index}`}
                                        control={<Checkbox
                                            checked={checkedStates.webTechnologies.includes(element)}
                                            onChange={(e) => handleCheckboxChange(e.target.checked, index, TechnologyTags, 'webTechnologies')}
                                        />}
                                        label={element} />
                                ))}
                            </FormGroup>
                        </List>
                    </Collapse>

                    <ListItemButton onClick={() => handleClick('webFrameworks')}>
                        <ListItemText primary="Web Frameworks" style={{ color: 'white' }} />
                        {open.webFrameworks ? <ExpandLess sx={{color: "white"}}/> : <ExpandMore sx={{color: "white"}}/>}
                    </ListItemButton>
                    <Collapse in={open.webFrameworks} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className="technologyGroup" sx={{ maxHeight: 200, overflow: 'auto', color: 'grey.400' }}>
                            <FormGroup>
                                {Object.keys(WebFrameworkTags).map((element, index) => (
                                    <FormControlLabel
                                        key={`framework-${index}`}
                                        control={<Checkbox
                                            checked={checkedStates.webFrameworks.includes(element)}
                                            onChange={(e) => handleCheckboxChange(e.target.checked, index, WebFrameworkTags, 'webFrameworks')} />}
                                        label={element}
                                    />
                                ))}
                            </FormGroup>
                        </List>
                    </Collapse>

                    <ListItemButton onClick={() => handleClick('customTag')} >
                        <ListItemText primary="Search Custom Tag" style={{ color: 'white' }} />
                        {open.customTag ? <ExpandLess sx={{color: "white"}}/> : <ExpandMore sx={{color: "white"}}/>}
                    </ListItemButton>
                    <Collapse in={open.customTag} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Form
                                style={{ display: 'flex', alignItems: 'center', padding: '8px' }}
                                onSubmit={handleCustomTagSubmit}
                            >

                                <InputGroup>
                                    <Form.Control
                                        placeholder="Add Custom Tag"
                                        value={customTag}
                                        onChange={(e) => setCustomTag(e.target.value)}
                                    />
                                    <Button type="submit" variant="contained" style={{ backgroundColor: '#F26430', color: 'white' }}>
                                        Add
                                    </Button>
                                </InputGroup>
                            </Form>
                        </List>
                    </Collapse>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='text' style={{ color: 'white' }} onClick={handleClear}>Clear Filters</Button>
                    </div>
                </List>
            </div>
        </Container>
    )
}
export default FilterBox;