import { useEffect, useState } from "react";
import ProfilePage from "../components/shared/ProfileView";
import { RetrieveUserInfoViaId } from "../services/userService";
import { UserType } from "../types/UserType";
import { useParams } from "react-router-dom";

const AltUserProfilePageWrapper = () => {
  const [user, setUser] = useState<UserType | undefined>();

  const { id } =  useParams();;
  
  useEffect(() => {
    const fetchUser = async () => {
      if (id) {
        setUser(await RetrieveUserInfoViaId(parseInt(id.toString())));
      }
    };

    fetchUser();
  }, [id]); 

  return <ProfilePage user={user} />;
}
export default AltUserProfilePageWrapper