import axios from "axios";
import { CommendationType } from "../types/CommendationType";
import { SetAuthToken } from "./helperService";
import { config } from "../Utils/baseUrl";
const baseUrl = `${config.url.API_URL}/Commendations`;

export function getCommendations(userId: number): Promise<CommendationType[]> {
  SetAuthToken(axios)
  return axios.get(baseUrl + `/${userId}`, { withCredentials: true }).then((resp) => resp.data)
}
export async function createCommendation(commendation: CommendationType): Promise<CommendationType[]> {
  SetAuthToken(axios)
  return await axios.post(baseUrl, commendation, { withCredentials: true }).then((resp) => resp.data)
}
export async function deleteCommendation(commendationId: number): Promise<boolean> {
  SetAuthToken(axios)
  return await axios.delete(baseUrl + `/${commendationId}`, { withCredentials: true }).then(resp => resp.data)
}
export async function updateCommendation(commendation: CommendationType): Promise<CommendationType[]> {
  return await axios.post(baseUrl + `/update`, commendation, { withCredentials: true }).then(resp => resp.data)
}
