import * as React from 'react';
import { Carousel } from 'react-bootstrap';

interface props {
    images: string[]
}

const ImageCarousel: React.FC<props> = ({ images }) => {
    return (

        <Carousel style={{marginTop: "5%"}}>
            {images.map((image, index) => {

                return <Carousel.Item key={index}>
                    <img src={"data:image/png;base64," + image} style={{ width: "400px", height: "400px", objectFit: "cover" }} />
                </Carousel.Item>
            })}
          </Carousel>

    );
}
export default ImageCarousel

