import { useContext, useEffect, useState } from "react";
import { getCard, updateCard } from "../services/cardService";
import { CardType } from "../types/CardType";
import ImageCarousel from "../components/shared/ImageCarousel";
import { Container, Row, Col, Spinner, Alert } from "react-bootstrap";
import Styles from '../css/job/jobDetailsPage.module.css';
import { Button, Chip, Typography } from "@mui/material";
import { UserType } from "../types/UserType";
import { RetrieveUserInfoViaId } from "../services/userService";
import { format } from 'date-fns';
import InfoIcon from '@mui/icons-material/Info';
import EmailModal from "../components/job/EmailFormModal";
import SubmissionModal from "../components/job/SubmissionFormModal";
import CurrentUserContext from "../client/UserContextProvider";
import { TechnologyTags, WebFrameworkTags } from "../types/TechnologyTagsEnum";
import EditJobModal from "../components/job/EditJobFormModal";
import BountyModal from "../components/job/BountyModal";
import { confirmOrder, createBountyOrder } from "../services/paymentsService";
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import { PayPalConfirmOrderResponseType } from "../types/ConfirmOrderResponseType";
import { PayPalOrderResponseType } from "../types/PayPalOrderResponseType";
import { useNavigate, useParams } from "react-router-dom";
import DefaultProfilePicture from "../images/default-profile-pic.png"
const JobView = () => {
    const [cardDetails, setCardDetails] = useState<CardType>()
    const [posterDetails, setPosterDetails] = useState<UserType>()
    const [showEmailModal, setShowEmailModal] = useState(false)
    const [showSubmissionModal, setShowSubmissionModal] = useState(false)
    const [showEditJobModal, setShowEditJobModal] = useState(false)
    const [showBountyModal, setShowBountyModal] = useState(false)
    const [isLoadingCardDetails, setIsLoadingCardDetails] = useState(false)
    const [isLoadingPosterDetails, setIsLoadingPosterDetails] = useState(false)
    const [bountyAmount, setBountyAmount] = useState(0)
    const [paymentRequired, setPaymentRequired] = useState(false)
    const [submissionSuccess, setSubmissionSuccess] = useState(false)
    const [jobUpdatedSuccess, setJobUpdatedSuccess] = useState(false)
    const context = useContext(CurrentUserContext);
    const { currentUser } = context!
    const navigate = useNavigate()
    const { id } = useParams();
    useEffect(() => {
        const fetchCardData = async () => {
            if (id && !cardDetails) {
                setIsLoadingCardDetails(true)
                setIsLoadingPosterDetails(true)
                setCardDetails(await getCard(parseInt(id.toString())))
                setIsLoadingCardDetails(false)
            }
            if (cardDetails != undefined) {
                setPosterDetails(await RetrieveUserInfoViaId(cardDetails.creatorId!))
                if (cardDetails.pendingPayment && cardDetails.creatorId == currentUser?.id) {
                    setPaymentRequired(true)
                }
                setIsLoadingPosterDetails(false)
                setBountyAmount(cardDetails.jobBounty ?? 0)
            }
        }
        fetchCardData()

    }, [cardDetails, id])
    useEffect(() => {
        const confirmPaymentOrder = async (tokenId: string) => {
            var orderPaidFor: PayPalConfirmOrderResponseType = await confirmOrder(tokenId)
            if (orderPaidFor.isSuccess) {
                setBountyAmount(orderPaidFor.bountyAmount)
                window.location.href = `/job/${id}`
            } else {
                window.location.href = "/profile/dashboard"
            }
        }
        const urlParams = new URLSearchParams(window.location.search);
        const tokenId = urlParams.get('token');
        if (tokenId && id) {
            confirmPaymentOrder(tokenId)
        }
    }, [id])
    useEffect(() => {
        setTimeout(() => setSubmissionSuccess(false), 3000)
        setTimeout(() => setJobUpdatedSuccess(false), 3000)
    }, [submissionSuccess, jobUpdatedSuccess])
    const formatDate = (date: string) => {
        var formatedDate = format(new Date(date), "dd/MM/yyyy")
        return formatedDate
    }
    const daysFromDate = (date: string) => {
        const dt1 = new Date();
        const dt2 = new Date(date);
        return Math.floor(
            (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
                Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
            (1000 * 60 * 60 * 24)
        );
    }
    const onEmailModalClose = () => {
        setShowEmailModal(false)
    }
    const onSubmissionModalClose = () => {
        setShowSubmissionModal(false)
    }
    const onEditJobModalClose = () => {
        setShowEditJobModal(false)
    }
    const onBountyModalClose = () => {
        setShowBountyModal(false)
    }
    const createSkillChips = () => {
        var chips = cardDetails?.tags?.map((tag, index) => {
            if (tag != null && tag != "") {
                var matchingKey = Object.keys(TechnologyTags).find(key => key.toLowerCase() === tag.toLowerCase());
                if (!matchingKey) {
                    matchingKey = Object.keys(WebFrameworkTags).find(key => key.toLowerCase() === tag.toLowerCase())
                }
                if (matchingKey) {
                    var indexOf = Object.keys(TechnologyTags).indexOf(matchingKey)
                    if (indexOf < 0) {
                        indexOf = Object.keys(WebFrameworkTags).indexOf(matchingKey)
                        return <Chip key={index} className={Styles.skillChip} style={{ backgroundColor: Object.values(WebFrameworkTags)[indexOf], margin: "1%", color: "black" }} label={matchingKey} />
                    }
                    return <Chip key={index} className={Styles.skillChip} style={{ backgroundColor: Object.values(TechnologyTags)[indexOf], margin: "1%", color: "black" }} label={matchingKey} />
                } else {

                    return <Chip key={index} className={Styles.skillChip} style={{ backgroundColor: "#6761A8", margin: "1%", color: "black" }} label={tag} />
                }
            }
        })
        return chips
    }

    const resolvePayment = async () => {
        var payPalOrderResponse: PayPalOrderResponseType = await createBountyOrder(0, cardDetails?.id!, currentUser?.id!) //will use existing payment entries purchase price for bounty
        cardDetails!.tokenId = payPalOrderResponse.id
        await updateCard(cardDetails!)
        for (let i = 0; i < payPalOrderResponse.links.length; i++) {
            const link = payPalOrderResponse.links[i];
            if (link.rel == "payer-action") {
                window.open(link.href, '_blank');
            }
        }
    }

    return (
        <>
            {submissionSuccess && <Alert style={{ textAlign: "center", width: "60%", margin: "0 auto", marginTop: "2%", marginBottom: "2%" }} variant="success">Submission was successfully sent to card owner!</Alert>}
            {jobUpdatedSuccess && <Alert style={{ textAlign: "center", width: "60%", margin: "0 auto", marginTop: "2%", marginBottom: "2%" }} variant="success">Job was successfully updated!</Alert>}
            {cardDetails && <Container>
                <Row>
                    {paymentRequired && <Alert style={{ textAlign: "center" }} variant="warning">Card is pending payment and will not show up to users. <span onClick={resolvePayment} style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }} >Click Here to resolve payment</span>. If you have already paid - please reload the page.</Alert>}
                    <Col xs={12} xl={4}>
                        {cardDetails?.images && cardDetails.images.length > 0 && <div id={Styles.imageCarousel}><ImageCarousel images={cardDetails.images} /></div>}
                        {cardDetails?.images?.length == 0 && <div id={Styles.imageCarousel} style={{ backgroundColor: "grey" }}>
                            <p style={{ textAlign: "center", paddingTop: "40%" }}>No images provided</p>
                        </div>}
                        <div id={Styles.detailsWrapper}>
                            <h2>Bounty: ${Intl.NumberFormat().format(bountyAmount).toString()}</h2>
                            <h2>Accepted Submission: {cardDetails?.acceptedSubmission == true ? <>Yes</> : <>No</>}</h2>
                            <h2>Number of Submissions: {cardDetails?.submissions.length}</h2>
                            {posterDetails && cardDetails?.finishDate && <h2>Days Remaining: {daysFromDate(cardDetails?.finishDate!)}</h2>}
                            {cardDetails && <h2>Date Posted: {formatDate(cardDetails?.createdOn!)}</h2>}
                        </div>
                    </Col>
                    <Col xs={12} xl={8}>
                        <div id={Styles.descriptionWrapper}>
                            <h1 style={{ maxWidth: "100%", overflowWrap: "break-word" }}>{cardDetails?.jobTitle}</h1>
                            <div id={Styles.chipWrapper}>
                                {createSkillChips()}
                            </div>
                            <hr />
                            <h1>Project Description</h1>
                            {isLoadingCardDetails ? <Spinner /> : <p style={{ maxWidth: "100%", overflowWrap: "break-word" }}>{cardDetails?.jobDescription}</p>}
                            <h1>Poster</h1>
                            <div id={Styles.posterWrapper}>
                                {isLoadingPosterDetails ? <Spinner /> : <><Container>
                                    <Row className="align-items-center">
                                        <Col xs={6} md={4}>
                                            {<img id={Styles.posterPicture} src={posterDetails?.profilePicture != null ? "data:image/png;base64, " + posterDetails.profilePicture : DefaultProfilePicture} onClick={() => { navigate(`/profile/${posterDetails?.id}`) }} />}
                                        </Col>
                                        <Col xs={6} md={8} className="align-items-center">
                                            <h2>
                                                {posterDetails?.username}
                                            </h2>
                                            <p><small>{posterDetails?.firstName} {posterDetails?.lastName}</small></p>
                                            {posterDetails && <h3>Member Since: {formatDate(posterDetails.accountCreationDate!)}</h3>}
                                            {cardDetails?.acceptedSubmission ? <p style={{ backgroundColor: "#2A2D34", textAlign: "center" }}><InfoIcon /> This job has been completed</p> : cardDetails?.submissions?.find(x => x.userId == currentUser?.id) != null ? <p><InfoIcon /> You have already submitted to this job</p> : <p></p>}
                                            <div id={Styles.buttons}>

                                                {cardDetails?.creatorId != currentUser?.id && <><Button id={Styles.contactButton} onClick={(e) => {
                                                    setShowEmailModal(true)
                                                }} >Contact</Button>
                                                    {(cardDetails?.submissions?.find(x => x.userId == currentUser?.id) || cardDetails?.acceptedSubmission) ?
                                                        <Button id={Styles.submitProjectButton} style={{ opacity: 0.3, backgroundColor: "grey" }} disabled>Submit Project</Button> :
                                                        <Button id={Styles.submitProjectButton} onClick={() => { setShowSubmissionModal(true) }}>Submit Project</Button>}</>}
                                                {cardDetails?.creatorId == currentUser?.id && <Button id={Styles.submitProjectButton} onClick={() => setShowEditJobModal(true)}>Edit Job</Button>}
                                                {cardDetails?.creatorId == currentUser?.id && <Button id={Styles.increaseBountyButton} onClick={() => setShowBountyModal(true)} disabled={paymentRequired} style={{ padding: "10px" }}><AttachMoneyRoundedIcon />Increase Bounty</Button>}
                                                <EmailModal showModal={showEmailModal} onClose={onEmailModalClose} posterName={posterDetails?.firstName} posterEmail={posterDetails?.email} />
                                                <SubmissionModal showModal={showSubmissionModal} onClose={onSubmissionModalClose} posterDetails={posterDetails!} cardDetails={cardDetails!} setSubmissionSuccess={setSubmissionSuccess} />
                                                {showEditJobModal && <EditJobModal showModal={showEditJobModal} onClose={onEditJobModalClose} cardDetails={cardDetails!} setJobUpdatedSuccess={setJobUpdatedSuccess} setCardDetails={setCardDetails} />}
                                                <BountyModal showModal={showBountyModal} onClose={onBountyModalClose} cardDetails={cardDetails!} />

                                            </div>
                                        </Col>
                                    </Row>
                                </Container></>}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>}
        </>
    )
}
export default JobView