import { createContext} from "react";
import { UserType } from "../types/UserType";

interface CurrentUserContextProps {
    currentUser: UserType | undefined;
    clearCurrentUser: () => void;
  }
  
  // Create the context with default values
  const CurrentUserContext = createContext<CurrentUserContextProps | undefined>(undefined);
  
  export default CurrentUserContext;
