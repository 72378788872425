const landingPageText = {
    introduction: <><p>Are you a <b>small business owner</b> yearning for a sleek, professional website without breaking the bank?</p>
      <p>Or perhaps you're a budding <b>software engineer</b> craving real-world projects that ignite your creativity and propel your portfolio to new heights?</p>
      <p>Look no further! <b style={{ color: "#F26430" }}>Build-My-Idea</b> is the platform where your dreams converge into reality.</p>
      <p>Gone are the days of settling for cookie-cutter websites or mundane coding exercises.</p>
      <p>We connect <b>small business owners</b> with talented <b>developers</b> eager to breathe life into their visions.</p>
      <p>Our unique model ensures affordability for business owners and meaningful compensation for <b>developers</b>, fostering a symbiotic relationship that fuels progress and success.</p>
      <p>With <b style={{ color: "#F26430" }}>Build-My-Idea</b>, small business owners have the freedom to set their own project budget. You pay only the amount you're comfortable with, plus a 10% service fee. If you decide to remove your job posting, you receive a full refund minus the service fee. </p>
      <p>Developers receive the full bounty offered by the business owner when their work is accepted, ensuring they are fairly compensated for their expertise and effort.</p>
      <p>Our platform is designed to make collaboration <b style={{ color: "#009DDC" }}>risk-free</b> and rewarding for both parties.</p>
      <p>Join us at Build-My-Idea and unlock the potential of collaboration.</p>
      <p>Whether you're a visionary entrepreneur or a coding virtuoso, your next big breakthrough awaits.</p>
      <p>Let's build something extraordinary together.</p></>,
    howItWorks: <><p>For Developers (Free Tier):</p>
      <p><b>1) Sign Up:</b> Create a developer account for free on Build-My-Idea.</p>
      <p><b>2) Browse Job Cards:</b> Explore a variety of job cards posted by business owners detailing their website requirements.</p>
      <p><b>3) Submit Work:</b> Choose a job card that aligns with your skills and interests, then develop the website according to the owner's specifications.</p>
      <p><b>4) Submit for Approval:</b> Once the website is completed, submit your work to the owner for approval. This can be done through a link to a GitHub Pages site, an email with images of the site, or via a video call for direct feedback.</p>
      <p><b>5) Approval and Payment Release:</b> If the owner approves the website, the payment is released from escrow to your designated bank account. You get paid for your hard work!</p>
      <p><b>For Business Owners (Paid Tiers):</b></p>
      <p><b>1) Choose a Tier:</b> Sign up for a subscription plan based on your needs. The tiers include Free (for developers), Basic (1 job card posting), and Premium (2 job card postings).</p>
      <p><b>2) Post Job Cards:</b> Create detailed job cards outlining your website requirements, including design preferences, functionalities needed, etc.</p>
      <p><b>3) Review Submissions:</b> Receive submissions from developers who have completed your job card. Review their work and provide feedback or request revisions as needed.</p>
      <p><b>4) Approve and Release Payment:</b> Once you're satisfied with a developer's work, approve the website. The payment held in escrow is then released to the developer's account, and you now have a brand-new website for your business!</p>
    </>,
    faq: <><p>For Business Owners:</p>
      <b>1. How does Build-My-Idea work for business owners?</b>
      <p>Build-My-Idea connects business owners with talented developers to create custom websites. Owners can post job cards outlining their requirements, review developer submissions, and approve the final website for payment release.</p>
      <b>2. What types of websites can I get built on Build-My-Idea?</b>
      <p>You can get a wide range of websites built, including e-commerce sites, portfolio websites, blogs, informational websites, and more.</p>
      <b>3. How do I ensure the quality of work from developers?</b>
      <p>Owners have the opportunity to review developer profiles, portfolios, and submitted work before approving a website. Additionally, owners can request revisions to ensure the final product meets their expectations.</p>
      <b>4. How do I communicate with developers?</b>
      <p>Communication with developers can be done through various channels, including messaging on the platform, email, or video calls for direct feedback and discussion.</p>
      <b>5. What if I'm not satisfied with the website?</b>
      <p>If you're not satisfied with the initial submission, you can request revisions from the developer to address any concerns or changes needed.</p>
      <b>6. How are payments handled on Build-My-Idea?</b>
      <p>Payments are securely held in escrow until the website is approved by the owner. Once approved, the payment is released to the developer's designated bank account.</p>
      <p>For Developers:</p>
      <b>1. How do I join Build-My-Idea as a developer?</b>
      <p>You can signup for a free developer account on the platform to start browsing job cards and submitting your work.</p>
      <b>2. What kind of projects can I work on as a developer?</b>
      <p>You can work on a variety of projects posted by business owners, ranging from simple portfolio websites to complex e-commerce sites.</p>
      <b>3. How do I get paid for my work?</b>
      <p>Developers earn a share of the subscription fees paid by business owners. Payments are released from escrow to the developer's designated bank account upon approval of the website by the owner.</p>
      <b>4. Can I communicate directly with business owners?</b>
      <p>Yes, developers can communicate directly with business owners through messaging on the platform, email, or video calls for clarifications and feedback.</p>
      <b>5. How can I improve my chances of getting selected for a project?</b>
      <p>Completing your profile with relevant skills and experience, showcasing a strong portfolio, and submitting high-quality work aligned with the owner's requirements can improve your chances of selection.</p>
      <b>6. What if I encounter issues during a project?</b>
      <p>If you encounter any issues during a project, you can reach out to our support team for assistance and guidance.</p>
    </>
  };
  export default landingPageText;