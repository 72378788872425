import React, { useContext, useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import CurrentUserContext from '../../client/UserContextProvider';
import { EmailType } from '../../types/EmailType';
import { sendEmail } from '../../services/emailService';
import Styles from '../../css/job/emailModal.module.css'
import { emailFormModalText } from '../../Text/emails';
interface EmailModalProps {
    showModal: boolean;
    onClose: () => void;
    posterName: string | undefined;
    posterEmail: string | undefined;
}
const EmailModal: React.FC<EmailModalProps> = ({ showModal, onClose, posterName, posterEmail }) => {
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const context = useContext(CurrentUserContext);
const { currentUser } = context!
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        const emailData: EmailType = {
            from: currentUser?.email!,
            to: posterEmail!,
            message: emailFormModalText(posterName!, currentUser?.firstName!, message),
            subject: "You have received a message from Build-My-Idea",
            sendersName: currentUser?.firstName!
        }
        setLoading(true)
        await sendEmail(emailData);
        setLoading(false)
        onClose()
        setMessage("")
    };

    return (
        <Modal show={showModal} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Send A Message to Poster<br/><small style={{fontSize: "small"}}>Poster will see your email and firstname</small></Modal.Title>
                
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="recipientEmail">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={message}
                            rows={4}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Enter message to poster"
                            required
                        />
                    </Form.Group>
                    <Button type="submit" id={Styles.sendButton} disabled={loading}>{loading ? <Spinner />: <>Send</>}</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EmailModal;
