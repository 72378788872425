import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useContext, useEffect, useState } from 'react';
import AccountMenu from './menuDropdown';
import HomeIcon from '@mui/icons-material/Home';
import Styles from '../../css/shared/menu.module.css'
import UserSearch from './UserSearch';
import { UserType } from '../../types/UserType';
import React from 'react';
import { LogoutUser } from '../../services/authService';
import { Link } from 'react-router-dom';
import CurrentUserContext from '../../client/UserContextProvider';
interface MenuProps {
  setCurrentUser: (user: UserType | undefined) => void;
}
const Menu: React.FC<MenuProps> = ({ setCurrentUser }) => {
  const context = useContext(CurrentUserContext);
const { currentUser } = context!
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(currentUser != null && currentUser != undefined)
  const Logout = async () => {
    await LogoutUser();
    setIsLoggedIn(false)
    setCurrentUser(undefined)
    window.location.href = "/login";
  };
  useEffect(()=> setIsLoggedIn(currentUser != null && currentUser != undefined),[currentUser])
  return (
    <div id={Styles.menu}>
      <Navbar bg="dark" variant="dark">
        <Container className="justify-content-end">
          <Link to="/"><Navbar.Brand id={Styles.menuTitle}>Build-My-Idea</Navbar.Brand></Link>
          <Nav className="me-auto" />

          {isLoggedIn && <div id={Styles.searchWrapper}><UserSearch /></div>}

          {isLoggedIn && <Link id={Styles.homeIcon} to="/home"><HomeIcon fontSize="large" style={{ color: "#999" }} /></Link>}
          {isLoggedIn && <AccountMenu logout={async () => { await Logout() }} isGoogleAuthenticated={false} />}
          {!isLoggedIn && <p id={Styles.loginSignupButtons}><Link id={Styles.loginButton} to="/login">login</Link> / <Link id={Styles.signupButton} to="/sign-up">signup</Link></p>}
        </Container>
      </Navbar>
    </div>
  );
}

export default Menu;

