import React, { useEffect, useState, ComponentType, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { CheckLoggedIn } from '../services/authService';
import CurrentUserContext from '../client/UserContextProvider';

interface PrivateRouteProps {
  element: ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element: Component, ...rest }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] = useState<boolean | undefined>(undefined);
  const [accountIsActivated, setAccountIsActivated] = useState<boolean | null>(null)
  const [loading, setLoading] = useState<boolean>(true);
  const context = useContext(CurrentUserContext);
  const { currentUser } = context!;
  const location = useLocation();

  useEffect(() => {
    const checkStatus = async () => {
      setLoading(true);
      const loggedIn = await CheckLoggedIn();
      setIsLoggedIn(loggedIn);
      if (loggedIn && currentUser) {
        setHasAcceptedTermsAndConditions(currentUser.hasAcceptedTermsAndConditions);
        setAccountIsActivated(currentUser.isActivated!)
      }
      setLoading(false);
    };

    checkStatus();
  }, [currentUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
  if (accountIsActivated != null && !accountIsActivated) {
    return <Navigate to="/sign-up/activate-account" />;
  }
  if (isLoggedIn && hasAcceptedTermsAndConditions != null && hasAcceptedTermsAndConditions === false && location.pathname !== '/terms-and-conditions-accept') {
    return <Navigate to="/terms-and-conditions-accept" />;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
