import { useState } from "react"
import { Alert, Button, Form, Spinner } from "react-bootstrap"
import Styles from "../css/login/forgotPassword.module.css"
import { useNavigate } from "react-router-dom"
import { ResetUserPassword } from "../services/authService"
const ForgotPassword = () => {
    const [email, setEmail] = useState("")
    const [showAlert, setShowAlert] = useState(false)
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)
    var navigate = useNavigate()
    const RequestResetLink = async () => {
        setLoading(true)
        var resp = await ResetUserPassword(email)
            if (resp) {
                setShowAlert(true)
                setTimeout(() => { setShowAlert(false) }, 5000)
            } else {
                setShowError(true)
                setTimeout(() => { setShowError(false) }, 5000)
            }
        setLoading(false)

    }

    return (<>
        {showAlert && <Alert variant="success" id={Styles.alertBanner}>If an account with this email is found - please find an email in your inbox with a password reset link</Alert>}
        {showError && <Alert variant="danger" id={Styles.alertBanner}>Something went wrong, please try again later</Alert>}
        <div id={Styles.wrapper}>
            <Form onSubmit={(e) => {
                e.preventDefault()
                RequestResetLink()
            }}>
                <h1>Input Email</h1>
                <small>A password reset link will be emailed to your email if an account exists</small>
                <br />
                <br />
                <Form.Control type="email" id={Styles.emailInput} placeholder="example@gmail.com" value={email} onChange={(e) => { setEmail(e.target.value) }} required/>
                <br />
                <Button type="submit" style={{ marginTop: "2%", backgroundColor: "#009DDC", border: "none", float: "right"}} disabled={loading}>{loading ? <Spinner style={{height: "20px", width: "20px"}} /> : "Confirm"}</Button>
                <Button style={{ marginTop: "2%", marginRight: "1%", backgroundColor: "#F26430", border: "none", float: "right" }} onClick={() => { navigate("/login") }}>Back</Button>
            </Form>
            <p></p>
        </div>
    </>)
}
export default ForgotPassword