import { Modal, Spinner } from "react-bootstrap";
import { UserType } from "../../types/UserType";
import Styles from "../../css/profile/requestChangesModal.module.css"
import { Alert, Button, TextField, Tooltip } from '@mui/material';
import { useState } from "react";
import { updateSubmission } from "../../services/submissionService";
import { SubmissionType } from "../../types/SubmissionType";
interface Props {
  showModal: boolean;
  onClose: () => void;
  currentUser: UserType | undefined;
  handleSubmitChangeRequest: (messageValue: string, from: string, to: string) => Promise<void>;
  submission: SubmissionType | undefined;
}
const RequestChangesModal: React.FC<Props> = ({ showModal, onClose, currentUser, handleSubmitChangeRequest, submission }) => {
  const maxCharacterLimit = 1000;
  const minCharacterLimit = 100;
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [messageValue, setMessageValue] = useState("")
  
  const sendEmail = async () => {
    if (submission != null) {
      try {
        setLoading(true)
        await handleSubmitChangeRequest(messageValue, currentUser?.email!, submission?.posterEmail!)
        submission.changeRequestsRemaining = submission.changeRequestsRemaining - 1
        await updateSubmission(submission)
        setLoading(false)
        setMessageValue("")
        onClose()
      } catch {
        setError(true)
        setTimeout(() => setError(false), 3000)
      }
    } else {
      setError(true)
        setTimeout(() => setError(false), 3000)
    }
  }
  return (
    <>
      <Modal show={showModal} onHide={() => { onClose() }}>
        <Modal.Header closeButton closeVariant="white" style={{ backgroundColor: "#2A2D34" }}>
          <Modal.Title id={Styles.requestChangesText}>Request Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body id={Styles.body}>
          {error && <Alert severity="error">Something has gone wrong - please try again later</Alert>}
          <p className={Styles.inputLabel}>TO:</p>
          <p className={Styles.inputLabel} style={{borderBottom: "2px dotted rgba(255,255,255,0.3)"}}>{submission?.posterEmail}</p>
          <p className={Styles.inputLabel}>FROM:</p>
          <p className={Styles.inputLabel} style={{borderBottom: "2px dotted rgba(255,255,255,0.3)"}}>{currentUser?.email}</p>
          <p className={Styles.inputLabel}>MESSAGE:</p>
          <TextField
            value={messageValue}
            onChange={(e) => setMessageValue(e.target.value)}
            inputProps={{ maxLength: maxCharacterLimit }}
            multiline
            rows={5}
            style={{ width: "100%", marginTop: "20px", backgroundColor: "white", borderRadius: "5px" }} />
          <p style={{ color: messageValue.length >= maxCharacterLimit ? "red" : "white", textAlign: "right" }}>{messageValue.length}/{maxCharacterLimit}</p>
          <Tooltip
            title={messageValue.length < minCharacterLimit ? `Minimum ${minCharacterLimit} characters required` : ''}
          >
            <span style={{ display: 'inline-block', float: "right" }}>
              <Button
                disabled={messageValue.length < minCharacterLimit}
                id={Styles.submitChangeRequestButton}
                style={{
                  backgroundColor: "#009DDC",
                  color: "white",
                  marginTop: "15px",
                  float: "right",
                  opacity: messageValue.length < minCharacterLimit ? "0.5" : "1"
                }}
                onClick={async () => await sendEmail()}
              >
                {loading ? <Spinner /> : <>Submit Change Request - {submission?.changeRequestsRemaining} Remaining</>}
              </Button>
            </span>
          </Tooltip>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default RequestChangesModal