import Button from 'react-bootstrap/Button';
import { ChangeEvent, useContext, useState } from 'react';
import { UpdateUserInfo } from '../../services/userService';
import Styles from '../../css/sign-up/signUpForm.module.css';
import { Input, ThemeProvider } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { UserType } from '../../types/UserType';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { StyledTextField, Theme } from '../shared/styledTextField';
import SignUpTextFields from './SignUpTextFields';
import { UserRoleType } from '../../types/UserRoleType';
import { Link, useNavigate } from 'react-router-dom';
import InstagramImage from "../../images/Instagram-Logo-Color.png"
import CurrentUserContext from '../../client/UserContextProvider';
interface ChildProps {
    setProfilePic: React.Dispatch<React.SetStateAction<string | undefined>>;
    profilePic: string | undefined;
}
export interface SignUpDetailsFormData {
    jobTitle: string;
    location: string;
    description: string;
    skills: string[];
    skill: string;
    linkedIn: string;
    facebook: string;
    instagram: string;
    paymentEmail: string;
    userRole: UserRoleType;
}
const SignUpDetailsForm: React.FC<ChildProps> = ({ setProfilePic, profilePic }) => {
    var navigate = useNavigate()
    const context = useContext(CurrentUserContext);
    const { currentUser } = context!
    const [formData, setFormData] = useState<SignUpDetailsFormData>({
        jobTitle: "",
        location: "",
        description: "",
        skills: [],
        skill: "",
        linkedIn: "",
        facebook: "",
        instagram: "",
        paymentEmail: "",
        userRole: UserRoleType.Customer
    })

    const [isLookingForWork, setIsLookingForWork] = useState(false)

    const [showFacebookInput, setShowFacebookInput] = useState(false);
    const [showInstagramInput, setShowInstagramInput] = useState(false);
    const [showLinkedInInput, setShowLinkedInInput] = useState(false);


    const handleChange = (name: string, value: string) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const saveDetails = async () => {
        if (currentUser?.email != null) {
            const user: UserType = {
                id: currentUser.id!,
                profilePicture: profilePic ?? "",
                jobTitle: formData.jobTitle,
                location: formData.location,
                description: formData.description,
                skills: formData.skills,
                linkedin: formData.linkedIn,
                facebook: formData.facebook,
                instagram: formData.instagram,
                isLookingForWork: isLookingForWork,
                userRole: UserRoleType.Customer,
                paymentEmail: formData.paymentEmail,
                isOauthAccount: localStorage.getItem("isOauthAcc") == "true",
                hasAcceptedTermsAndConditions: undefined
            }
            await UpdateUserInfo(user)
        }
        navigate("/home")
    }

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64String = reader.result;
                setProfilePic(base64String?.toString());
            };
        }
    };

    return (
        <ThemeProvider theme={Theme}>
            <div id={Styles.signUpDetailsForm}>
                <h2 id={Styles.signUpHeading}>Signup</h2>

                <SignUpTextFields handleChange={handleChange} formData={formData} setFormData={setFormData} />
                <br />
                {/* Socials */}
                <div id={Styles.socialInputWrapper}>
                    {showLinkedInInput && <>
                        <StyledTextField style={{ width: "45%", marginRight: "1%" }} id="standard-basic" disabled={true} variant="standard" value={"https://www.linkedin.com/in/"} />
                        <StyledTextField style={{ width: "45%", marginRight: "1%", marginTop: "-16px" }} id="standard-basic" label="LinkedIn Username" variant="standard" inputProps={{ maxLength: 30 }}
                            name="linkedIn" onChange={(e) => handleChange(e.target.name, e.target.value)} value={formData.linkedIn} />
                    </>}
                    {showFacebookInput && <><div className={Styles.seperator} />
                        <StyledTextField style={{ width: "45%", marginRight: "1%" }} id="standard-basic" disabled={true} variant="standard" value={"https://www.facebook.com/"} />
                        <StyledTextField style={{ width: "45%", marginRight: "1%", marginTop: "-16px" }} id="standard-basic" label="Facebook Username" variant="standard" inputProps={{ maxLength: 30 }}
                            name="facebook" onChange={(e) => handleChange(e.target.name, e.target.value)} value={formData.facebook} />
                    </>}
                    {showInstagramInput && <><div className={Styles.seperator} />
                        <StyledTextField style={{ width: "45%", marginRight: "1%" }} id="standard-basic" disabled={true} variant="standard" value={"https://www.instagram.com/"} />
                        <StyledTextField style={{ width: "45%", marginRight: "1%", marginTop: "-16px" }} id="standard-basic" label="Instagram Username" variant="standard" inputProps={{ maxLength: 30 }}
                            name="instagram" onChange={(e) => handleChange(e.target.name, e.target.value)} value={formData.instagram} />
                    </>}
                </div>
                <br />
                <br />
                {/* Social Icons */}
                <div id={Styles.socialIconsContainer}>
                    <LinkedInIcon sx={{ width: "90px", height: "90px" }} className={Styles.socialIcon}
                        style={{ color: showLinkedInInput ? "#0077B5" : "#999" }}
                        onClick={() => setShowLinkedInInput(!showLinkedInInput)} />

                    <FacebookIcon sx={{ width: "90px", height: "90px" }} className={Styles.socialIcon}
                        style={{ color: showFacebookInput ? "#4267B2" : "#999" }}
                        onClick={() => setShowFacebookInput(!showFacebookInput)} />

                    {!showInstagramInput && <InstagramIcon sx={{ width: "90px", height: "90px" }} className={Styles.socialIcon}
                        onClick={() => setShowInstagramInput(!showInstagramInput)} />}

                    {showInstagramInput && <img id={Styles.instagramImage}
                        className={Styles.socialIcon} src={InstagramImage}
                        onClick={() => setShowInstagramInput(!showInstagramInput)} />}

                </div>
                {/* Looking for work */}
                <div className={Styles.seperator} />
                <p style={{ color: "#999" }}>I am looking for work {<Checkbox
                    onChange={() => {
                        setIsLookingForWork(!isLookingForWork)
                    }} sx={{
                        color: "#999",
                        '&.Mui-checked': {
                            color: "#999",
                        },
                    }} />}</p>
                {/* Payment email nomination */}
                <StyledTextField style={{ width: "100%", marginRight: "1%", marginTop: "-16px" }} id="standard-basic" label="PayPal Payment Email" variant="standard" inputProps={{ maxLength: 45 }}
                    name="paymentEmail" onChange={(e) => handleChange(e.target.name, e.target.value)} value={formData.paymentEmail} />
                <small style={{ color: "#999" }}>Nominate your email you want to receive payments from PayPal to - this can be done later in the settings page</small>
                <div className={Styles.seperator} />
                {/* File */}
                <Input
                    type="file"
                    onChange={handleImageChange}
                />
                <div className={Styles.seperator} />
                <Link to="/home">
                    <p id={Styles.skipForNowButton}>skip for now</p>
                </Link>
                <Button id={Styles.signUpButton} onClick={saveDetails}>
                    save
                </Button>
            </div>
        </ThemeProvider>
    );
}
export default SignUpDetailsForm
