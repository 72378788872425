import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useContext, useState } from 'react';
import { InputAdornment, IconButton, ThemeProvider } from '@mui/material';
import { StyledTextField, Theme } from '../../components/shared/styledTextField';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Styles from '../../css/login/loginForm.module.css';
import { Spinner } from 'react-bootstrap';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import React from 'react';
import { GoogleOAuthTokenSwapRequest, LoginUser } from '../../services/authService';
import CurrentUserContext from '../../client/UserContextProvider';
import { Link, useNavigate } from 'react-router-dom';
import { TokenSwapType } from '../../types/TokenSwapType';
interface ChildProps {
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginForm: React.FC<ChildProps> = ({ setError }) => {
  const context = useContext(CurrentUserContext);
const { currentUser } = context!
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate()

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const responseMessage = async (response: CredentialResponse) => {
    var loginResponse = await GoogleOAuthTokenSwapRequest(response.credential!)
    if (!loginResponse.requiresDetailsPage) {
      await navigate("/home")
      window.location.reload();
    } else {
      await navigate("/sign-up/details")
      window.location.reload();
    }
  };
  const errorMessage = (error: void) => {
    console.log(error);
  };

  return (
    <ThemeProvider theme={Theme}>
      <div id={Styles.loginForm}>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true)
            try {
              var response: TokenSwapType | null = await LoginUser(email, password, currentUser!);
              setLoading(false)
              console.log("now we're here", response)

              if (response && !response.requiresAccountActivation) {
                await navigate("/home")
                window.location.reload();
              } else if (response && response.requiresAccountActivation) {
                console.log("and now now we're here", response)
                await navigate("/sign-up/activate-account")
                window.location.reload();
              } else {
                setError(true);
                setLoading(false)
                setTimeout(() => setError(false), 3000);
              }
            } catch {
              setError(true);
              setLoading(false)
              setTimeout(() => setError(false), 3000);
            }
          }}
        >
          <h2 id={Styles.loginHeading}>LOGIN</h2>

          <StyledTextField
            fullWidth
            id="standard-basic"
            label="Email"
            variant="standard"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <div id={Styles.seperator} />
          <StyledTextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            id="standard-basic"
            label="Password"
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton style={{ color: '#999' }} onClick={handleTogglePassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <div id={Styles.loginButtonWrapper}>
            <p id={Styles.forgotPassword} onClick={() => navigate("/forgot-password")}>Forgot Password</p>
            <Button id={Styles.loginButton} type="submit" disabled={loading}>
              {!loading ? "Login" : <Spinner />}
            </Button>
            <p id={Styles.noAccount}>Don&apos;t have an account?</p>
            <Link to="/sign-up" >
              <Button id={Styles.signUpButton}>
                signup
              </Button>
            </Link>
            <p id={Styles.oauthPrompt}>Or signup/login using:</p>
            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
          </div>
        </Form>
      </div>
    </ThemeProvider>
  );
};

export default LoginForm;
