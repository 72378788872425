import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import Styles from '../../css/home/searchBar.module.css'
import { Dispatch, ReactElement, SetStateAction, useCallback, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import { FilterType } from '../../types/FilterType';
import { debounce } from 'lodash';

interface SearchBarProps {
    setSortBy: (newValue: string) => void;
    sortBy: string;
    setQuery: (newValue: string) => void;
    query: string;
    executeSearch: () => void;
    setShowCreateJobModal: (value: boolean) => void;
    filterButton: ReactElement<any, any>;
    setFilterTags: Dispatch<SetStateAction<string[]>>;
    setFilters: Dispatch<SetStateAction<FilterType[]>>;
    setRange: Dispatch<SetStateAction<string>>;
}
const SearchBar: React.FC<SearchBarProps> = ({ setSortBy, sortBy, setQuery, query, executeSearch, setShowCreateJobModal, filterButton,
    setFilterTags, setFilters, setRange
 }) => {
    const [sortOpen, setSortOpen] = useState(false);
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
    const [searchTerm, setSearchTerm] = useState("")
    const handleSort = (event: React.MouseEvent<HTMLElement>, choice: string) => {
        setSortAnchorEl(event.currentTarget);
        setSortOpen(!sortOpen);
        setSortBy(choice);
    }
    const handleDebounce = useCallback(
        debounce((term: string) => {
            setQuery(term)
        }, 300),
        []
      );
    const handleSearch = () => {
        executeSearch()
    };

    return (
        <Container>
            <Row>
                <div id={Styles.searchBarWrapper} >
                    <Col xs={10} >
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSearch()
                            }}>
                            <InputGroup>
                                <Form.Control
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value)
                                        handleDebounce(e.target.value)
                                    }}
                                    value={searchTerm}
                                    id={Styles.searchInput}
                                    autoComplete="off"
                                    placeholder='Search for a specific type of work...(portfolio, html, functional etc..)'
                                />

                                <Button id={Styles.searchButton} type='submit' >Search</Button>
                                <InputGroup.Text id={Styles.searchIcon}>
                                    <SearchIcon type='submit' color="action" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form>

                        <br />
                        <div id={Styles.filterButtons}>

                            <Button id={Styles.createJobButton} onClick={() => setShowCreateJobModal(true)}>Create Job</Button>
                            <Button id={Styles.sortCardsButton} onClick={(e) => handleSort(e, sortBy)}>{sortBy}<KeyboardArrowDownIcon /></Button>
                            {filterButton}
                            <Menu
                                anchorEl={sortAnchorEl}
                                open={sortOpen}
                                onClose={() => setSortOpen(false)}
                            >
                                <MenuItem onClick={(e) => handleSort(e, "Latest")}>
                                    Latest
                                </MenuItem>
                                <MenuItem onClick={(e) => handleSort(e, "Most Submissions")}>
                                    Most Submissions
                                </MenuItem>
                                <MenuItem onClick={(e) => handleSort(e, "Bounty Ascending")}>
                                    Bounty Ascending
                                </MenuItem>
                                <MenuItem onClick={(e) => handleSort(e, "Bounty Descending")}>
                                    Bounty Descending
                                </MenuItem>
                            </Menu>
                            <p style={{ margin: "1%", cursor: "pointer", color: "#6761A8" }} onClick={async () => {
                                setQuery("")
                                setSortBy("Sort By")
                                setRange("")
                                setFilters([])
                                setFilterTags([])
                            }}>clear</p>
                        </div>
                    </Col>
                </div>
            </Row>
        </Container>
    )
}

export default SearchBar;
