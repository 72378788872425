import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (<div style={{
        backgroundColor: "rgba(100,100,100,0.3)",
        textAlign: "center",
        width: "30%",
        margin: "0 auto",
        marginTop: "30vh",
        padding: "100px",
        borderRadius: "15px"
    }}>
        <h1>404: Page not found</h1>
        <Link to="/home">
            <Button style={{
                background: "green",
                marginTop: "20px"
            }}>Go Home</Button>
        </Link>
    </div>)
}

export default NotFound;