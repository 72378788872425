import DragAndDropUpload from "../components/profile/DragAndDropUpload";
import { deleteFile, getDownloadLink, getDownloadLinkForZipFile, getSubmissions, listFiles } from "../services/submissionService";
import { PaymentStateEnum, SubmissionType } from "../types/SubmissionType"
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Styles from "../css/profile/submissions.module.css";
import CurrentUserContext from "../client/UserContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { EmailType } from "../types/EmailType";
import { sendEmail } from "../services/emailService";
import { getCard, updateCard } from "../services/cardService";
import { RetrieveUserInfoViaId } from "../services/userService";
import PaymentEmailModal from "../components/profile/PaymentEmailModal";
import { NotifyCustomerEmail } from "../Text/emails";

const Submissions = () => {
    const [files, setFiles] = useState<string[]>([]);
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
    const [loadingState, setLoadingState] = useState(0)
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [checkedFiles, setCheckedFiles] = useState<string[]>([])
    const [masterChecked, setMasterChecked] = useState(false)
    const [userSubmissions, setUserSubmissions] = useState<SubmissionType[]>([])
    const [selectedSubmission, setSelectedSubmission] = useState<SubmissionType>()
    const [uploadStatus, setUploadStatus] = useState<string[]>([]);
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const context = useContext(CurrentUserContext);
const { currentUser } = context!
    useEffect(() => {
        const fetchFiles = async () => {
            if (selectedSubmission!.isAccepted && selectedSubmission!.paymentState == PaymentStateEnum.Unpaid) {
                const responseFiles = await listFiles(selectedSubmission!.cardGuid);
                setFiles(responseFiles);
            }
        };
        const fetchSubmissions = async () => {
            var submissions: SubmissionType[] = await getSubmissions(currentUser?.id!)
            setUserSubmissions(submissions)
        }
        if (selectedSubmission == null && currentUser != null) {
            fetchSubmissions();
        }
        if (selectedSubmission != null) {
            fetchFiles();
        }
    }, [selectedSubmission, currentUser]);
    useEffect(() => {
        if (downloadLoading || deleteLoading) {
            const interval = setInterval(() => {
                setLoadingState(prevState => (prevState < 3 ? prevState + 1 : 0));
            }, 500);
            return () => clearInterval(interval);
        }
    }, [downloadLoading, deleteLoading]);
    const retrieveAndOpenDownloadLink = async () => {

        setDownloadLoading(true)

        try {
            if (checkedFiles.length > 1) {
                const response = await getDownloadLinkForZipFile(selectedSubmission!.cardGuid, checkedFiles);
                if (response) {
                    const anchor = document.createElement('a');
                    anchor.href = response;
                    anchor.setAttribute('download', selectedSubmission!.cardTitle.replace(" ", "_") + ".zip");
                    document.body.appendChild(anchor);
                    anchor.click();
                    document.body.removeChild(anchor);
                }
            } else {
                if (checkedFiles.length != 0) {
                    const response = await getDownloadLink(selectedSubmission!.cardGuid, checkedFiles[0]);
                    if (response) {
                        const anchor = document.createElement('a');
                        anchor.href = response;
                        anchor.setAttribute('download', '');
                        document.body.appendChild(anchor);
                        anchor.click();
                        document.body.removeChild(anchor);
                    }
                }
            }

        } catch (error) {
            console.error('Failed to download the file:', error);
        } finally {
            setDownloadLoading(false)
        }
    };
    const deleteFileCheckedFiles = async () => {
        setDeleteLoading(true)
        var success = await deleteFile(selectedSubmission!.cardGuid, checkedFiles)
        if (success) {
            setFiles(files.filter((fileName, i) => !checkedFiles.includes(fileName)))
            setCheckedFiles([])
        }
        setMasterChecked(false)
        setDeleteLoading(false)
    }
    async function notifyCustomer(): Promise<boolean> {
        var card = await getCard(selectedSubmission?.cardId!)
        if (card && card.creatorId != null) {
            var owner = await RetrieveUserInfoViaId(card.creatorId)
            var emailObject: EmailType = {
                subject: "Your BRAND NEW website is ready to download!",
                message: NotifyCustomerEmail(selectedSubmission?.changeRequestsRemaining!),
                from: "buildmyidea.contact@gmail.com",
                to: owner.email!,
                sendersName: currentUser?.firstName!
            }
            await sendEmail(emailObject)
            const sevenDaysFromNow = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
            card.paymentReleaseDeadline = sevenDaysFromNow
            await updateCard(card)
            return true
        }
        else {
            return false
        }
    }
    const handleCheck = (isChecked: boolean, fileName: string) => {
        if (isChecked) {
            setMasterChecked(files.length == checkedFiles.length + 1 ? true : false)
            var newCheckedFiles = [...checkedFiles, fileName]
            setCheckedFiles(newCheckedFiles)
        } else {
            var newCheckedFiles = checkedFiles.filter(name => name != fileName)
            setCheckedFiles(newCheckedFiles)
            setMasterChecked(false)
        }
    }
    const handleMasterCheck = (isChecked: boolean) => {
        setMasterChecked(isChecked);
        if (isChecked) {
            setCheckedFiles(files);
        } else {
            setCheckedFiles([]);
        }
    };
    const handleSelectedSubmissionChange = (submission: SubmissionType) => {
        if (submission.isAccepted && submission.paymentState == PaymentStateEnum.Unpaid) {
            setSelectedSubmission(submission)
        }
    }

    const removeFileFromUpload = (fileToRemove: File) => {
        setFilesToUpload(filesToUpload.filter(x => x.name != fileToRemove.name))
    }
    const determineStatusColour = (status: string | undefined) => {
        if (status == undefined) {
            return "purple"
        }
        switch (status) {
            case "pending" || undefined:
                return "purple"
            case "completed":
                return "green"
            case "failed":
                return "red"
            default:
                return "red"
        }
    }
    return (
        <div style={{ marginTop: "15px" }}>
            {currentUser && <PaymentEmailModal currentUser={currentUser}/>}
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                        <div id={Styles.leftColumn}>

                            <div id={Styles.displaySubmissionsWrapper}>
                                <FormControl fullWidth>
                                    <InputLabel style={{ color: "white" }}>Submission</InputLabel>
                                    <Select
                                        sx={{
                                            background: "rgba(70, 75, 86, 0.60)",
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#2A2D34',
                                                borderRadius: "0"
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#009DDC',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#F26430',
                                            }
                                        }}
                                        style={{ color: "white" }}
                                        value={selectedSubmission?.cardTitle ? selectedSubmission?.cardTitle : "default"}
                                        label="Submission"
                                        onChange={(event) => {
                                            const selectedCardTitle = event.target.value;
                                            const submission = userSubmissions.find(submission => submission.cardTitle === selectedCardTitle);
                                            if (submission) {
                                                handleSelectedSubmissionChange(submission);
                                            }
                                        }}
                                    >
                                        <MenuItem value={"default"}>Please select a submission</MenuItem>;
                                        {userSubmissions != null && userSubmissions.length > 0 && userSubmissions.map((submission, index) => {
                                            if (submission.isAccepted && submission.paymentState == PaymentStateEnum.Unpaid) {
                                                return <MenuItem key={index} value={submission.cardTitle} style={{color: "black"}}>{submission.cardTitle}</MenuItem>;
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div id={Styles.displayFilesWrapper}>
                                <div id={Styles.displayFilesHeading}>
                                    {selectedSubmission != null && <>
                                        {files != null && files.length > 0 && <>
                                            <div id={Styles.uploadedFilesSelector}>
                                                <Checkbox
                                                    onChange={(e) => handleMasterCheck(e.target.checked)}
                                                    checked={masterChecked}
                                                    sx={{ color: "white" }}
                                                />
                                                <b style={{ color: "white" }}>Select all</b>
                                                <Button id={Styles.downloadButton} onClick={async () => await retrieveAndOpenDownloadLink()}>{downloadLoading ? `downloading${'.'.repeat(loadingState)}` : <>download selected</>}</Button>
                                                <Button id={Styles.deleteButton} onClick={async () => await deleteFileCheckedFiles()}>{deleteLoading ? `deleting${'.'.repeat(loadingState)}` : <>delete selected</>}</Button>
                                            </div>
                                            <div id={Styles.fileWrapper}>
                                                <FormGroup>
                                                    {files != null && files.length > 0 && files.map((file, index) => {
                                                        return <FormControlLabel key={index} sx={{ marginRight: "0", color: "white", backgroundColor: index % 2 == 0 ? "rgba(41, 43, 48, 0.4)" : "rgba(41, 43, 48, 0)" }} control={<Checkbox sx={{ color: "white" }} checked={checkedFiles.includes(file)}
                                                            onChange={(e) => handleCheck(e.target.checked, file)} />} label={file} />
                                                    })}
                                                </FormGroup>
                                            </div>
                                        </>}
                                    </>}
                                    {selectedSubmission == null || (files != null && files.length == 0) && <>
                                        <p id={Styles.displayFileTileMessage}>Uploaded files will show up here</p>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div id={Styles.rightColumn}>
                            <div id={Styles.displayUploadWrapper}>
                                {selectedSubmission != null && <>
                                    <DragAndDropUpload cardGuid={selectedSubmission.cardGuid}
                                        setFiles={setFiles}
                                        files={files}
                                        setUploadStatus={setUploadStatus}
                                        setFilesToUpload={setFilesToUpload}
                                        filesToUpload={filesToUpload}
                                        notifyCustomer={notifyCustomer}
                                        />
                                </>}
                                {selectedSubmission == null && <>
                                    <p id={Styles.uploadTileMessage}>Select a submission to upload files<DriveFolderUploadIcon style={{ fontSize: "128px", opacity: "0.8" }} /></p>
                                </>}
                            </div>
                            <div id={Styles.uploadFilesWrapper}>
                                <div id={Styles.pendingFiles} >
                                    {filesToUpload.map((file, index) => {
                                        console.log(uploadStatus[index])
                                        return <div key={file.name} className={Styles.fileInfo} style={{ backgroundColor: index % 2 == 0 ? "rgba(41, 43, 48, 0.4)" : "rgba(41, 43, 48, 0)" }}>
                                            <span>{file.name} -
                                                <span style={{ color: `${determineStatusColour(uploadStatus[index])}`, padding: "2px" }}>{uploadStatus[index] || 'pending'}</span>
                                                {uploadStatus[index] != "completed" && <DeleteIcon className={Styles.uploadFileRemove} onClick={() => {removeFileFromUpload(file)}} />}
                                            </span>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>)
}
export default Submissions