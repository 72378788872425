import { Alert, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap"
import { useContext, useEffect, useState } from "react"
import Styles from '../../css/profile/profile.module.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import CurrentUserContext from "../../client/UserContextProvider";
import { UserType } from "../../types/UserType";
import Commendations from "../profile/Commendations";
import { CommendationType } from "../../types/CommendationType";
import { getCommendations } from "../../services/commendationService";
import CommendationModal from "../profile/CommendationModal";
import ReportUserModal from "../profile/ReportUserModal";
import { Box, Tab, Tabs } from "@mui/material";
import EditProfileModal from "../profile/EditProfileModal";
import DefaultProfilePicture from "../../images/default-profile-pic.png"
interface ChildProps {
    user: UserType | undefined;
}
const ProfilePage: React.FC<ChildProps> = ({ user }) => {
    const context = useContext(CurrentUserContext);
const { currentUser } = context!
    const [aboutSelected, setAboutSelected] = useState(0)
    const [commendations, setCommendations] = useState<CommendationType[]>([])
    const [reportSubmitted, setReportSubmitted] = useState(false)
    const [linkedInLink, setLinkedInLink] = useState<string | undefined>(undefined)
    const [instagramLink, setInstagramLink] = useState<string | undefined>(undefined)
    const [facebookLink, setFacebookLink] = useState<string | undefined>(undefined)
    useEffect(() => {
        if (user != null) {
            user?.facebook ? setFacebookLink(`https://www.facebook.com/${user?.facebook}`) : setFacebookLink(undefined)
            user?.instagram ? setInstagramLink(`https://www.instagram.com/${user?.instagram}`) : setInstagramLink(undefined)
            user?.linkedin ? setLinkedInLink(`https://www.linkedin.com/in/${user?.linkedin}`) : setLinkedInLink(undefined)
        } else if (currentUser != null) {
            currentUser?.facebook ? setFacebookLink(`https://www.facebook.com/${currentUser?.facebook}`) : setFacebookLink(undefined)
            currentUser?.instagram ? setInstagramLink(`https://www.instagram.com/${currentUser?.instagram}`) : setInstagramLink(undefined)
            currentUser?.linkedin ? setLinkedInLink(`https://www.linkedin.com/in/${currentUser?.linkedin}`) : setLinkedInLink(undefined)
        }
        
        console.log(currentUser)
    }, [user, currentUser])

    const [showCommendationsModal, setShowCommendationsModal] = useState(false)
    const [showReportUserModal, setShowReportUserModal] = useState(false)
    const [showEditProfileModal, setShowEditProfileModal] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            const response = await getCommendations(user?.id!)
            setCommendations(response)
        }
        if (user) {
            fetchData()
        }
    }, [user])
    const onCloseCommendationsModal = () => {
        setShowCommendationsModal(false)
    }
    const onCloseReportUserModal = () => {
        setShowReportUserModal(false)
    }
    const onCloseEditProfileModal = () => {
        setShowEditProfileModal(false)
    }
    const convertToReadableDate = (dateString: string | undefined) => {
        if (dateString) {
            const date = new Date(dateString);
            const readableDate = date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
            return readableDate
        }
    }
    const ReportSubmittedAlert = () => {
        setReportSubmitted(true)
        setTimeout(() => { setReportSubmitted(false) }, 3000)
    }
    const TimeSinceTargetDate = (targetDateAsNumber: number) => {
        console.log(targetDateAsNumber)
        const targetDate = new Date(targetDateAsNumber);
        const currentDate = new Date();

        const timeDifferenceInMillis: number = +currentDate - +targetDate;
        const hoursDifference = (timeDifferenceInMillis / (1000 * 60 * 60) - 10) - 1;

        if (hoursDifference <= 1) {
            return <span>Now <span style={{ color: "green" }}>•</span></span>
        }
        if (hoursDifference > 24) {
            const daysDifference = hoursDifference / 24
            return <span>{Math.floor(daysDifference)} Days Ago</span>
        }
        return <span>{Math.floor(hoursDifference)} Hours Ago</span>
    }
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setAboutSelected(newValue);
    }
    return (
        <div id={Styles.profilePage}>
            {reportSubmitted && <Alert style={{ width: "40%", left: "30%", textAlign: "center", position: "absolute", zIndex: "999" }} variant="success">Report successfully submitted</Alert>}
            <Container>
                <Row>
                    <Col xs={12} md={3} className="d-flex justify-content-center">
                        <div>
                            <img id={Styles.profilePhoto} src={user?.profilePicture ? "data:image/png;base64," + user?.profilePicture : DefaultProfilePicture} alt="User profile" />
                            {user?.id == currentUser?.id && <>
                                <Button style={{ backgroundColor: "#6761A8", border: "none" }} onClick={() => { setShowEditProfileModal(true) }}>Edit Profile</Button></>}
                            <hr />
                            <div id={Styles.profileInfoSection}>
                                {user == undefined ? <Spinner /> : <><h2><b>Member Since:</b> <span>{convertToReadableDate(user?.accountCreationDate)}</span></h2>
                                    <h2><b>Submissions:</b> {user?.numberOfSubmissions ?? <span>0</span>}</h2>
                                    <h2><b>Accepted Submissions:</b> {user?.numberOfAcceptedSubmissions ?? <span>0</span>}</h2>
                                    <h2><b>Looking for Work:</b> <span>{user?.isLookingForWork ? "Yes" : "No"}</span></h2>
                                    <h2><b>Last Online:</b> {TimeSinceTargetDate(user?.lastOnline!)}</h2></>}
                            </div>
                            <h2 id={Styles.skillsHeading}><span>Skills</span></h2>
                            <ul>
                                {user && user.skills?.map((skill, index) => {
                                    return <li className={Styles.skillListItem} key={index}><span>{skill}</span></li>
                                })}
                            </ul>
                        </div>
                    </Col>
                    <Col xs={12} md={9} >

                        <div id={Styles.profileInfoWrapper}>
                            <h2 id={Styles.name}><span>{user?.firstName} {user?.lastName}</span></h2>
                            <small id={Styles.location}><LocationOnIcon />{user?.location}</small>
                            <p id={Styles.profession}>{user?.jobTitle}</p>

                            <div id={Styles.profileSelectBox}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={aboutSelected} onChange={handleTabChange} aria-label="basic tabs example">
                                        <Tab label={<p className={Styles.aboutAndCommendationsTabs} style={{ marginBottom: "0", color: !aboutSelected ? "#1976d2" : "white" }}><AccountCircleIcon /> About</p>} />
                                        <Tab label={<p className={Styles.aboutAndCommendationsTabs} style={{ marginBottom: "0", color: aboutSelected ? "#1976d2" : "white" }}><EmojiEventsIcon /> Commendations</p>} />
                                    </Tabs>
                                </Box>
                                <hr id={Styles.aboutCommendationsHR} />

                                {aboutSelected == 0 && <><div id={Styles.bioWrapper}>
                                    <h2 id={Styles.bio}>Bio</h2>
                                    {user == undefined ? <Spinner /> : <p id={Styles.description}>{user?.description}</p>}

                                </div>
                                    <div id={Styles.socialsWrapper}>
                                        <h2 id={Styles.socials}>Socials</h2>
                                        <div id={Styles.socialsButtons}>
                                            <a href={linkedInLink} target="_blank"><LinkedInIcon className={Styles.socialIcon} style={{ width: "90px", height: "90px", color: linkedInLink ? "#0077B5" : "#999" }} /></a>
                                            <a href={facebookLink} target="_blank"><FacebookIcon className={Styles.socialIcon} style={{ width: "90px", height: "90px", color: user?.facebook ? "#4267B2" : "#999" }} /></a>
                                            {!user?.instagram && <InstagramIcon className={Styles.socialIcon} style={{ width: "90px", height: "90px" }} />}
                                            {user?.instagram && <a href={instagramLink} target="_blank"><img style={{ width: "90px", height: "90px", transform: "scaleX(-1)", marginTop: "-1%" }} className={Styles.socialIcon} src='/static/images/Instagram-Logo-Color.png' /></a>}
                                        </div>
                                    </div>
                                </>}

                                {aboutSelected == 1 && <><div id={Styles.commendationsWrapper}>
                                    <div id={Styles.commendationPostWrapper}>
                                        {currentUser?.id != user?.id && <Button id={Styles.commendButton} onClick={() => setShowCommendationsModal(true)}>Commend {user?.firstName}</Button>}
                                        {commendations.length > 0 ? <Commendations setCommendations={setCommendations} commendations={commendations} /> : <p style={{ marginTop: "15%" }} >User has not yet received a commendation</p>}
                                    </div>
                                </div>
                                    {showCommendationsModal && <CommendationModal onClose={onCloseCommendationsModal} showModal={showCommendationsModal} userId={user?.id!} userName={user?.firstName!} setCommendations={setCommendations} />}
                                </>}

                            </div>
                            {currentUser?.id != user?.id && <p id={Styles.reportUser} onClick={() => setShowReportUserModal(true)}>Report User</p>}
                            {showReportUserModal && <ReportUserModal onClose={onCloseReportUserModal} showModal={showReportUserModal} userId={user?.id!} reportSubmittedAlert={ReportSubmittedAlert} />}
                            {showEditProfileModal && <EditProfileModal onClose={onCloseEditProfileModal} showModal={showEditProfileModal} user={currentUser!} />}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default ProfilePage