import { Container, Row, Col } from "react-bootstrap"
import SignUpForm from "../components/sign-up/SignUpForm"
import Styles from '../css/sign-up/signUpForm.module.css'
import Logo from "../images/Logo.png"
export default function SignUpPage() {
    return (
        <Container>
            <Row>
                <Col>
                    <SignUpForm />
                </Col>
                <Col>
                    <img id={Styles.signUpLogo} src={Logo} />
                </Col>
            </Row>
        </Container>
    )
}