import ProfilePage from "../components/shared/ProfileView";
import CurrentUserContext from "../client/UserContextProvider";
import { useContext } from "react";
const ProfilePageWrapper = () => {
    const context = useContext(CurrentUserContext)
    const { currentUser } = context!;
    
    return (
        <ProfilePage user={currentUser} />
    )
}
export default ProfilePageWrapper