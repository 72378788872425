interface Config {
  url: {
    API_URL: string;
  },
  auth: {
    GOOGLE_OAUTH_CLIENT_ID: string;
    GOOGLE_OAUTH_CLIENT_SECRET: string;
  };
}

const prod: Config = {
  url: {
    API_URL: 'https://build-my-idea.com/api/v1'
  },
  auth: {
    GOOGLE_OAUTH_CLIENT_ID: "774036737777-4qr26sm6jneemscd04qtkmetj892laav.apps.googleusercontent.com",
    GOOGLE_OAUTH_CLIENT_SECRET: "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"
  }
};

const dev: Config = {
  url: {
    API_URL: 'https://localhost:7128/api/v1'
  },
  auth: {
    GOOGLE_OAUTH_CLIENT_ID: "354613621769-4j9o79bl8bj7uc5hk6lbijfba088rect.apps.googleusercontent.com",
    GOOGLE_OAUTH_CLIENT_SECRET: "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"
  }
};

export const config: Config = process.env.NODE_ENV === 'development' ? dev : prod;
export const bountyMinAmount = 10