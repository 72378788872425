import Card from 'react-bootstrap/Card';
import { CardType } from '../../types/CardType';
import Styles from '../../css/home/jobCard.module.css'
import { Chip } from '@mui/material';
import { TechnologyTags, WebFrameworkTags } from '../../types/TechnologyTagsEnum';
interface props {
  onClick: () => void
  card: CardType
}
const JobCard:React.FC<props> = ({card, onClick}) => {
  const {jobTitle, tags} = card
  const createSkillChips = () => {
    var chips = tags?.map((tag, index) => {
      var matchingKey = Object.keys(TechnologyTags).find(key => key.toLowerCase() === tag.toLowerCase());
      if (!matchingKey) {
          matchingKey = Object.keys(WebFrameworkTags).find(key => key.toLowerCase() === tag.toLowerCase())
      }
      if (matchingKey) {
        var indexOf = Object.keys(TechnologyTags).indexOf(matchingKey)
        if (indexOf < 0) {
            indexOf = Object.keys(WebFrameworkTags).indexOf(matchingKey)
            return <Chip key={index} style={{backgroundColor: Object.values(WebFrameworkTags)[indexOf], margin: "1%", color: "black", fontSize: "large"}} label={matchingKey} />
        }
        return <Chip key={index} style={{backgroundColor: Object.values(TechnologyTags)[indexOf], margin: "1%", color: "black", fontSize: "large"}} label={matchingKey} />
      } else {

        return <Chip key={index} style={{backgroundColor:"#6761A8", color: "black", fontSize: "large"}}  label={tag} />
      }
    })
    return chips
  }
  return (
    <Card id={Styles.card} className="mx-2 mb-2" onClick={onClick}>
        {<p style={{position: "absolute", width: "100%"}}>{createSkillChips()}</p>}
      {card?.images?.[0] && <div >
        <Card.Img variant="top" className={Styles.cardImage} src={"data:image/png;base64, "+ card.images[0]} />
        </div>}
      {!card?.images?.[0] && <div style={{backgroundColor: "grey", height: "250px"}} />}
      <Card.Body>
        <Card.Title id={Styles.cardTitle}>{jobTitle}</Card.Title>
        <Card.Subtitle><span style={{color: "red"}}>Bounty:</span> ${Intl.NumberFormat().format(card.jobBounty!)}</Card.Subtitle>
      </Card.Body>
    </Card>
  );
}

export default JobCard;