import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Styles from '../../css/sign-up/signUpForm.module.css'
import { StyledTextField, Theme } from '../../components/shared/styledTextField';
import { ThemeProvider } from '@mui/material'
import { SignUp } from '../../services/authService';
import { Alert, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
interface FormData {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirmation: string;
  [key: string]: string;
}

function SignUpForm() {
  const initialFormData: FormData = {
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirmation: "",
  };
  const navigate = useNavigate()
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [passwordMatchAlert, setPasswordMatchAlert] = useState(false)
  const [usernameValidationErrors, setUsernameValidationErrors] = useState<string[]>([])
  const [emailValidationErrors, setEmailValidationErrors] = useState<string[]>([])
  const [firstNameValidationErrors, setFirstNameValidationErrors] = useState<string[]>([])
  const [lastNameValidationErrors, setLastNameValidationErrors] = useState<string[]>([])
  const [exceptionMessage, setExceptionMessage] = useState("")
  const [existingAccountAlert, setExistingAccountAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [validationErrors, setValidationErrors] = useState<React.JSX.Element>(
    <>
      <span>More than 8 characters<br /></span>
      <span>At least 1 capital letter<br /></span>
      <span>At least 1 number<br /></span>
      <span>At least 1 special character<br /></span>
    </>
  )

  const capitalLetterRegex = /[A-Z]/
  const specialCharRegex = /[!@#\$%\^&\*\(\)_\+\-=\[\]\{\};:'",<>\./?\\|]/;
  const numberRegex = /[0-9]/;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validateInputs = (): boolean => {
    const numberErrorMessage = "Please remove all numbers"
    const specialCharacterErrorMessage = "Please remove all special characters"
    const emptyInputErrorMessage = "Must not be empty"
    const minLengthErrorMessage = "Must be at least 2 characters";
    const maxLengthErrorMessage = "Must be at most 10 characters";
    let isErrors = false
    let usernameErrors = []
    let emailErrors = []
    let firstNameErrors = []
    let lastNameErrors = []
    //Validate not empty
    if (formData.email.trim() == "" && !emailValidationErrors.includes(emptyInputErrorMessage)) {
      emailErrors.push(emptyInputErrorMessage)
      isErrors = true
    }

    // Validate length
    if (formData.username.length < 2) {
      usernameErrors.push(minLengthErrorMessage);
      isErrors = true;
    } else if (formData.username.length > 10) {
      usernameErrors.push(maxLengthErrorMessage);
      isErrors = true;
    }

    if (formData.firstName.length < 2) {
      firstNameErrors.push(minLengthErrorMessage);
      isErrors = true;
    } else if (formData.firstName.length > 10) {
      firstNameErrors.push(maxLengthErrorMessage);
      isErrors = true;
    }

    if (formData.lastName.length < 2) {
      lastNameErrors.push(minLengthErrorMessage);
      isErrors = true;
    } else if (formData.lastName.length > 10) {
      lastNameErrors.push(maxLengthErrorMessage);
      isErrors = true;
    }

    //Validate no numbers
    if (formData.firstName != "" && numberRegex.test(formData.firstName) && !firstNameValidationErrors.includes(numberErrorMessage)) {
      firstNameErrors.push(numberErrorMessage)
      isErrors = true
    }
    if (formData.lastName != "" && numberRegex.test(formData.lastName) && !lastNameValidationErrors.includes(numberErrorMessage)) {
      lastNameErrors.push(numberErrorMessage)
      isErrors = true
    }

    //Validate no special characters
    if (formData.firstName != "" && specialCharRegex.test(formData.firstName) && !firstNameValidationErrors.includes(specialCharacterErrorMessage)) {
      firstNameErrors.push(specialCharacterErrorMessage)
      isErrors = true
    }
    if (formData.lastName != "" && specialCharRegex.test(formData.lastName) && !lastNameValidationErrors.includes(specialCharacterErrorMessage)) {
      lastNameErrors.push(specialCharacterErrorMessage)
      isErrors = true
    }
    setUsernameValidationErrors(usernameErrors)
    setEmailValidationErrors(emailErrors)
    setFirstNameValidationErrors(firstNameErrors)
    setLastNameValidationErrors(lastNameErrors)
    setTimeout(() => { setUsernameValidationErrors([]) }, 4000)
    setTimeout(() => { setEmailValidationErrors([]) }, 4000)
    setTimeout(() => { setFirstNameValidationErrors([]) }, 4000)
    setTimeout(() => { setLastNameValidationErrors([]) }, 4000)
    if (formData.password !== formData.passwordConfirmation) {
      setPasswordMatchAlert(true)
      setTimeout(() => setPasswordMatchAlert(false), 4000);
      return false;
    }
    return !isErrors
  };

  const validateUserPassword = (password: string, finalCheck: boolean = false) => {
    var lengthCheck = password.length >= 8
    var capitalLetterCheck = capitalLetterRegex.test(password)
    var numberCheck = numberRegex.test(password)
    var specialCharacterCheck = specialCharRegex.test(password)
    setValidationErrors(<>
      {!lengthCheck && <span style={{ color: finalCheck && !lengthCheck ? "red" : "" }}>More than 8 characters<br /></span>}
      {!capitalLetterCheck && <span style={{ color: finalCheck && !capitalLetterCheck ? "red" : "" }}>At least 1 capital letter<br /></span>}
      {!numberCheck && <span style={{ color: finalCheck && !numberCheck ? "red" : "" }}>At least 1 number<br /></span>}
      {!specialCharacterCheck && <span style={{ color: finalCheck && !specialCharacterCheck ? "red" : "" }}>At least 1 special character<br /></span>}
    </>)
    if (lengthCheck && capitalLetterCheck && numberCheck && specialCharacterCheck) {
      return true
    }
    return false
  }
  return (
    <ThemeProvider theme={Theme}>
      {existingAccountAlert && <Alert variant="warning" style={{ width: "40%", left: "30%", top: "100px", textAlign: "center", position: "absolute", zIndex: "999" }}>{exceptionMessage}</Alert>}
      <div id={Styles.signUpForm}>
        <h2 id={Styles.signUpHeading}>SIGNUP</h2>
        <Form onSubmit={async (e) => {
          e.preventDefault()
          setLoading(true)
          if (validateInputs() && validateUserPassword(formData.password, true)) {
            try {
              var statusCode = await SignUp(formData.email, formData.password, formData.username, formData.firstName, formData.lastName)
              if (statusCode == 200) {
                localStorage.setItem("email", formData.email)
                navigate("/sign-up/activate-account")
              }
            }
            catch (e) {
              if (e instanceof Error) {
                if (e.message.includes("409")) {
                  setExceptionMessage("Account already exists")
                } else {
                  setExceptionMessage(e.message);
                }
              } else {
                setExceptionMessage('An unexpected error occurred.');
              }
              setExistingAccountAlert(true)
              setTimeout(() => { setExistingAccountAlert(false) }, 4000)
            }
          }
          setLoading(false)
        }
        }>

          <StyledTextField
            fullWidth
            error={usernameValidationErrors.length > 0}
            helperText={usernameValidationErrors.concat().toString()}
            type="text"
            id="standard-basic"
            label="Username"
            variant="standard"
            name="username"
            onChange={handleChange}
          />
          <div className={Styles.seperator} />
          <StyledTextField
            fullWidth
            error={emailValidationErrors.length > 0}
            helperText={emailValidationErrors.concat().toString()}
            inputProps={{ maxLength: 40 }}
            type="email"
            id="standard-basic"
            label="Email"
            variant="standard"
            name="email"
            onChange={handleChange}
          />
          <div className={Styles.seperator} />
          <StyledTextField
            fullWidth
            error={firstNameValidationErrors.length > 0}
            helperText={firstNameValidationErrors.concat().toString()}
            inputProps={{ maxLength: 30 }}
            type="text"
            id="standard-basic"
            label="First Name"
            variant="standard"
            name="firstName"
            onChange={handleChange}
          />
          <div className={Styles.seperator} />
          <StyledTextField
            fullWidth
            error={lastNameValidationErrors.length > 0}
            helperText={lastNameValidationErrors.concat().toString()}
            inputProps={{ maxLength: 30 }}
            type="text"
            id="standard-basic"
            label="Last Name"
            variant="standard"
            name="lastName"
            onChange={handleChange}
          />
          <div className={Styles.seperator} />
          <StyledTextField
            fullWidth
            helperText={validationErrors}
            inputProps={{ maxLength: 30 }}
            type="password"
            id="standard-basic"
            label="Password"
            variant="standard"
            name="password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              validateUserPassword(e.target.value)
              handleChange(e)
            }}
          />
          <StyledTextField
            fullWidth
            error={passwordMatchAlert}
            helperText={passwordMatchAlert ? "Passwords do not match" : ""}
            inputProps={{ maxLength: 30 }}
            type="password"
            id="standard-basic"
            label="Confirm Password"
            variant="standard"
            name="passwordConfirmation"
            onChange={handleChange}
          />
          <Link to="/login">
            <Button id={Styles.signUpBackButton}>back</Button>
          </Link>
          <Button id={Styles.signUpButton} type="submit">
            {loading ? <Spinner /> : <>sign up</>}
          </Button>
        </Form>
      </div>
    </ThemeProvider>
  );
}
export default SignUpForm
