import axios from "axios";
import { CardType } from "../types/CardType";
import { SetAuthToken } from "./helperService";
import { config } from "../Utils/baseUrl";
const baseUrl = `${config.url.API_URL}/Cards`;
axios.defaults.withCredentials = true;
export function getCards(page: number, numberOfCards: number, query: string = "", sortBy: string, tags: string[] = [], range: string): Promise<CardType[]> {
  
  const queryParams = query !== "" ? `&query=${encodeURIComponent(query)}` : "";
  const tagsParams = tags.length > 0 ? `&tags=${tags}` : "";
  return axios.get(baseUrl + `?page=${page}&number=${numberOfCards}${queryParams}&sortBy=${sortBy}${tagsParams}&range=${range}`).then((resp) => resp.data)
}
export async function createCard(card: CardType) {
  
  return await axios.post(baseUrl, card, { withCredentials: true }).then(resp => resp.data).catch(ex => ex)
}
export async function updateCard(card: CardType) {
  
  return await axios.post(baseUrl + `/update`, card, { withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
}
export async function deleteCard(id: number): Promise<CardType[]> {
  return await axios.delete(baseUrl + `/${id}`, { withCredentials: true }).then(resp => resp.data)
}
export async function getCard(id: number): Promise<CardType> {
  
  return await axios.get(baseUrl + `/${id}`, { withCredentials: true }).then(resp => resp.data)
}
export async function getAllCardsForUser(id: number): Promise<CardType[]> {
  
  return await axios.get(baseUrl + `/user/${id}`, { withCredentials: true }).then(resp => resp.data)
}
export async function archiveCard(id: number): Promise<boolean> {
  
  return await axios.patch(baseUrl + `/archive/${id}`, { withCredentials: true }).then(resp => resp.data)
}
export async function getMaxBounty(): Promise<number> {
  
  return await axios.get(baseUrl + `/max-bounty`, { withCredentials: true }).then(resp => resp.data)
}
