import { StyledTextField, Theme } from "../components/shared/styledTextField"
import { ThemeProvider } from "@mui/material"
import { useContext, useState } from "react"
import { Alert, Button, Form, Spinner } from "react-bootstrap"
import Styles from "../css/sign-up/activateAccount.module.css"
import { ActivateUserAccount, CheckAccountIsActivated, LoginUser, LogoutUser } from "../services/authService"
import { Link, useNavigate } from "react-router-dom"
import CurrentUserContext from "../client/UserContextProvider"
const ActivateAccount = () => {
    const context = useContext(CurrentUserContext);
    const { currentUser, clearCurrentUser } = context!
    const [token, setToken] = useState("")
    const [password, setPassword] = useState("")
    const [isActivated, setIsActivated] = useState(false)
    const [error, setError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [resendCodeSuccess, setResendCodeSuccess] = useState(false)
    const [resendCodeFail, setResendCodeFail] = useState(false)
    const [resendCodeLoading, setResendCodeLoading] = useState(false)
    const [submitCodeLoading, setSubmitCodeLoading] = useState(false)
    const [loginLoading, setLoginLoading] = useState(false)
    var navigate = useNavigate();
    const onSubmit = async () => {
        setSubmitCodeLoading(true)
        if (token != "") {
            var success = await ActivateUserAccount(token)
            if (success) {
                setIsActivated(true)
            } else {
                setError(true)
                setTimeout(() => setError(false), 5000)
            }
        }
        setSubmitCodeLoading(false)
    }
    const onLogin = async () => {
        setLoginLoading(true)
        try {
            var isSuccess = await LoginUser(localStorage.getItem("email")!, password, currentUser!)
            if (isSuccess) {
                await navigate("/sign-up/details")
                window.location.reload();
            } else {
                setPasswordError(true)
                setTimeout(() => setPasswordError(false), 5000)
            }
        } catch {
            setPasswordError(true)
            setTimeout(() => setPasswordError(false), 5000)
        }
        setLoginLoading(false)
    }
    const backToLogin = async () => {
        console.log(currentUser)
        if (currentUser) {
            await LogoutUser()
            clearCurrentUser()
        }
        navigate("/login")
    }
    const handleResendCode = async () => {
        try {
            setResendCodeLoading(true)
            var success = await CheckAccountIsActivated(localStorage.getItem("email")!)
            if (success) {
                setResendCodeSuccess(true)
                setTimeout(() => { setResendCodeSuccess(false) }, 3000)
            } else {
                setResendCodeFail(true)
                setTimeout(() => setResendCodeFail(false), 3000)
            }
        } catch {
            setResendCodeFail(true)
            setTimeout(() => setResendCodeFail(false), 3000)
        }
        setResendCodeLoading(false)
    }
    return (
        <ThemeProvider theme={Theme}>
            <div id={Styles.container}>
                {resendCodeSuccess && <Alert className={Styles.alert} variant="success">Token was successfully resent to your email</Alert>}
                {resendCodeFail && <Alert className={Styles.alert} variant="danger">Token failed to resend - please try again later</Alert>}
                {error && <Alert className={Styles.alert} variant="danger">Token is incorrect or has expired. Click &apos;resend code&apos; and a new code will be emailed to you</Alert>}
                {passwordError && <Alert className={Styles.alert} variant="danger">Password is incorrect - please try again.</Alert>}
                <div id={Styles.wrapper}>
                    {!isActivated && <Form onSubmit={(e) => {
                        e.preventDefault()
                        onSubmit()
                    }}>
                        <h1 className={Styles.heading}>We have <span style={{ color: "#6761A8" }}>sent a code to your email</span>. To <span style={{ color: "#009DDC" }}>activate your account</span>, please paste it here:</h1>
                        <StyledTextField
                            className={Styles.textField}
                            label="Token"
                            placeholder="eg 123456"
                            variant="standard"
                            onChange={(e) => {
                                setToken(e.target.value);
                            }}
                            value={token}
                            autoComplete="off"
                        />
                        <Button className={Styles.submitButtons} type="submit">{submitCodeLoading ? <Spinner /> : <>submit</>}</Button>
                        <Button id={Styles.resendCodeButton} onClick={handleResendCode}>{resendCodeLoading ? <Spinner /> : <>resend code</>}</Button>
                        <Button className={Styles.backButtons} onClick={backToLogin}>back</Button>
                    </Form>}
                    {isActivated && <Form onSubmit={async (e) => {
                        e.preventDefault()
                        onLogin()
                    }}>
                        <h1 className={Styles.heading}>Your account has been <span style={{ color: "#009B72" }}>successfully activated</span>! Please confirm your password to login:</h1>
                        <StyledTextField
                            className={Styles.textField}
                            label="Password"
                            placeholder="password"
                            variant="standard"
                            type="password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            value={password}
                        />
                        <Button className={Styles.submitButtons} type="submit">{loginLoading ? <Spinner /> : <>login</>}</Button>
                        <Button className={Styles.backButtons} onClick={backToLogin}>back</Button>
                    </Form>}
                </div>
            </div>
        </ThemeProvider>
    )
}
export default ActivateAccount