import { deleteCard } from "../../services/cardService";
import { CardType } from "../../types/CardType";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import Styles from "../../css/profile/dashboard.module.css"
import { useState } from "react";
import { Link } from "@mui/icons-material";
interface DeleteJobModalProps {
    showModal: boolean;
    onClose: () => void;
    id: number;
    removeCardFromList: (id: number) => void;
    handleChange: () => void;
    card: CardType;
}
const DeleteJobModal: React.FC<DeleteJobModalProps> = ({ showModal, onClose, id, removeCardFromList, handleChange, card }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setIsLoading(true)
            await deleteCard(id)
            removeCardFromList(id)
            handleChange()
            onClose()
            setIsLoading(false)
        } catch {
            setIsError(true)
            setIsLoading(false)
            setInterval(() => setIsError(false), 4000)
        }
        
    }
    return (
        <Modal show={showModal} onHide={() => { onClose() }}>
            <Modal.Header closeButton>
                <Modal.Title>Delete job</Modal.Title>

            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="message">
                        {isError && <Alert variant="danger">Something went wrong when deleting this card. Please try again later or <Link href="/contact">contact us here</Link></Alert>}
                        <Form.Label>You are about to <span style={{ color: "red" }}>delete this job and all related submissions</span>. Are you sure you want to continue?</Form.Label>
                        {card.jobBounty! > 0 && <div className={Styles.deleteModalNote}>
                            <p style={{fontSize: "10px", marginBottom: "0"}}>Note</p>
                            <Form.Text>There is a bounty associated with this card, by deleting the following card you will be refunded: <span style={{ color: "red" }}>${card.jobBounty}</span>. This amount will be refunded to the account the money came from originally.</Form.Text>
                            <Form.Text><br/><b>Please allow up to 5 business days for the funds to be received</b></Form.Text>
                        </div>}

                    </Form.Group>
                    <Button type="submit" style={{ margin: "1%", backgroundColor: "#009B72", border: "none" }}>{isLoading ? <Spinner /> : <>Yes</>}</Button>
                    <Button onClick={onClose} style={{ margin: "1%", backgroundColor: "red", border: "none" }}>No</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default DeleteJobModal;