// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAOp6C-eht06LymN9hCVyjUVpyfvyNzDAA",
    authDomain: "buildmyidea-e5672.firebaseapp.com",
    projectId: "buildmyidea-e5672",
    storageBucket: "buildmyidea-e5672.appspot.com",
    messagingSenderId: "1014807265633",
    appId: "1:1014807265633:web:f29052c922de79d083c520",
    measurementId: "G-NQ218V9BD2"
  };

  const firebaseConfigPROD = {
    apiKey: "AIzaSyD2XCWZetaxGnrBZtP_ELsOjUxRsy7vkHw",
    authDomain: "bmi-prod-75aa6.firebaseapp.com",
    projectId: "bmi-prod-75aa6",
    storageBucket: "bmi-prod-75aa6.appspot.com",
    messagingSenderId: "774036737777",
    appId: "1:774036737777:web:4e7ec10fc340750cbbe350",
    measurementId: "G-4YT42SZE7V"
  };

const firebaseApp = initializeApp(process.env.NODE_ENV === 'development' ? firebaseConfig : firebaseConfigPROD);
const auth = getAuth(firebaseApp);

export default auth;
