import CurrentUserContext from "../../client/UserContextProvider";
import { createCommendation } from "../../services/commendationService";
import { CommendationType } from "../../types/CommendationType";
import { useState, useContext } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { commendationMaxChars } from "../../Utils/valueSettings";
interface CommendationModalProps {
    showModal: boolean;
    onClose: () => void;
    userId: number;
    userName: string;
    setCommendations: React.Dispatch<React.SetStateAction<CommendationType[]>>;
}
const CommendationModal: React.FC<CommendationModalProps> = ({showModal, onClose, userId, userName, setCommendations}) => {

    const [message, setMessage] = useState("")
    const [characterLimitAlert, setCharacterLimitAlert] = useState(false)
    const context = useContext(CurrentUserContext);
const { currentUser } = context!
    
    const minimumCharacterLimit = 1;
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (message.length < commendationMaxChars && message.length > minimumCharacterLimit) {
            const commendation: CommendationType = {
                posterId: currentUser!.id!,
                userId: userId,
                commendationMessage: message,
                datePosted:undefined,
                likes: [],
            }
            var response = await createCommendation(commendation)
            console.log(response)
            commendation.poster = currentUser
            setCommendations(response)
            onClose()
        } else {
            setCharacterLimitAlert(true)
            setTimeout(() => {setCharacterLimitAlert(false)}, 3000)
        }
    }
    return (
        <Modal show={showModal} onHide={() => {
            setMessage("")
            onClose()
        }}>
            <Modal.Header closeButton style={{backgroundColor: "#2A2D34", color: "white"}}>
                <Modal.Title>Leave A Commendation</Modal.Title>

            </Modal.Header>
            <Modal.Body style={{backgroundColor: "#2A2D34", color: "white"}}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="message">
                        {characterLimitAlert && <Alert variant="danger">Message must be less than {commendationMaxChars} and more than {minimumCharacterLimit} characters</Alert>}
                        <Form.Label>Commendation Message</Form.Label>
                        <Form.Control
                            value={message}
                            as="textarea"
                            rows={4}
                            maxLength={commendationMaxChars}
                            minLength={minimumCharacterLimit}
                            onChange={(e) => { setMessage(e.target.value) }}
                            placeholder={`Write something nice about your experience with ${userName}`}
                        />
                        <p style={{color: message.length >= commendationMaxChars ? "red" : "white"}}>{message.length}/{commendationMaxChars}</p>
                        <br />
                    </Form.Group>
                    <Button type="submit" style={{backgroundColor: "#F26430", border: "none", float: "right"}}>Send</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default CommendationModal;