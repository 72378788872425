import { AxiosStatic } from "axios";

export const SetAuthToken = (axios: AxiosStatic) => {
    try {
        const token = localStorage.getItem("token")
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
    }
    catch{}
}