import { useContext, useEffect, useState } from "react"
import { IconButton, Drawer, List, ListItemText, ListItemButton, TableHead, TableRow, TableBody, Avatar, Button } from '@mui/material';
import { Table, TableContainer, TableCell } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Container, Row, Col } from "react-bootstrap";
import { RetrieveAllUsersInfo, UpdateUserInfo } from "../services/userService";
import { UserType } from "../types/UserType";
import UserCard from "../components/admin/userCard";
import UserInformation from "../components/admin/userTransactions";
import { useNavigate } from "react-router-dom";
const Admin = () => {
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState<UserType[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        getUsers();
    }, [])

    const toggleDrawer = () => {
        setOpen(!open);
    }

    const getUsers = async () => {
        const retrievedUsers = await RetrieveAllUsersInfo();
        setUsers(retrievedUsers);
    }

    const handleActivate = async () => {
        if (selectedUser) {
            const updatedUser = { ...selectedUser, isActivated: true };
            const response = await UpdateUserInfo(updatedUser);
            if (response.status === 200) {
                setSelectedUser(updatedUser);
                setUsers(users.map(user => (user.id === updatedUser.id ? updatedUser : user)));
            }
        }
    };
    const handleDeactivate = async () => {
        if (selectedUser) {
            const updatedUser = { ...selectedUser, isActivated: false };
            const response = await UpdateUserInfo(updatedUser);
            if (response.status === 200) {
                setSelectedUser(updatedUser);
                setUsers(users.map(user => (user.id === updatedUser.id ? updatedUser : user)));
            }
        }
    };

    const handleAdminButton = async () => {
        if (selectedUser) {
            const updatedUser = { ...selectedUser, userRole: selectedUser.userRole === 0 ? 1 : 0 }
            const response = await UpdateUserInfo(updatedUser);
            if (response.status === 200) {
                setSelectedUser(updatedUser);
                setUsers(users.map(user => (user.id === updatedUser.id ? updatedUser : user)));
            }
        }
    };

    return (
        <Container>
            <IconButton edge="start" onClick={toggleDrawer}>
                <MenuIcon />
            </IconButton>
            <Drawer open={open} variant="persistent">
                <List>
                    <ListItemButton onClick={() => setOpen(false)}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText primary="Customer Dashboard" />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText primary="Business Dashboard" />
                    </ListItemButton>
                </List>
            </Drawer>
            <div>
                <Row>
                    <h1 style={{ color: '#F26430' }} className="text-center mb-3">Admin Dashboard</h1>
                </Row>
                <Row className="mx-5">
                    <TableContainer>
                        <Table sx={{ backgroundColor: 'white' }}>
                            <TableHead sx={{ backgroundColor: '#808080' }}>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Id</TableCell>
                                    <TableCell>Username</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Admin</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => (
                                    <TableRow key={user.id} onClick={() => setSelectedUser(user)} style={{ cursor: 'pointer' }}>
                                        <TableCell>
                                            <Avatar src={"data:image/png;base64," + user.profilePicture} alt="Profile" />
                                        </TableCell>
                                        <TableCell>{user.id}</TableCell>
                                        <TableCell>{user.username}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.firstName}</TableCell>
                                        <TableCell>{user.lastName}</TableCell>
                                        <TableCell>{user.accountCreationDate}</TableCell>
                                        <TableCell>{user.userRole ? "Yes" : "No"}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Row>
                <Row className="mx-5 mt-3">
                    <Col md={4} lg={3}>
                        {selectedUser && (
                            <UserCard
                                user={selectedUser}
                                onActivate={handleActivate}
                                onDeactivate={handleDeactivate}
                                onSetAdmin={handleAdminButton}
                            />
                        )}
                    </Col>
                    <Col md={8} lg={9}>
                        {selectedUser && (
                            <UserInformation
                                user={selectedUser}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </Container>
    )
};
export default Admin