import { useContext, useEffect, useState } from "react";
import { Navbar, Container, Nav, Col, Row } from "react-bootstrap"
import CurrentUserContext from "../../client/UserContextProvider";
export default function Footer() {
    const context = useContext(CurrentUserContext);
    const { currentUser } = context!
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>()
    useEffect(() => {
        setIsLoggedIn(currentUser != null && currentUser != undefined)
    }, [currentUser])
    return (
        <>
            <Navbar id="footer-container" bg="dark" data-bs-theme="dark">
                <Navbar.Brand id="footer-logo" href="/">Build-My-Idea</Navbar.Brand>
                <Nav id="footer">
                    {!isLoggedIn && <Nav.Link href="/login">Login</Nav.Link>}
                    <Nav.Link href="/faq">FAQ</Nav.Link>
                    <Nav.Link href="/contact">Contact Us</Nav.Link>
                    <Nav.Link href="/terms-and-conditions">Ts&Cs</Nav.Link>
                </Nav>
            </Navbar>
        </>
    )
};