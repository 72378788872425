import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button, Spinner } from 'react-bootstrap';
import { UserType } from '../../types/UserType';
import { Payment } from '../../types/PaymentType'
import { getUserPayments } from '../../services/paymentsService';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getSubmissions, updateSubmission } from '../../services/submissionService';
import { PaymentStateEnum, SubmissionType } from '../../types/SubmissionType';
import { getCard } from '../../services/cardService';
import { CardType } from '../../types/CardType';

interface UserInformationProps {
    user: UserType | null;
}

const UserInformation: React.FC<UserInformationProps> = ({ user }) => {
    const transactionsTable = "transactions"
    const submissionsTable = "submissions"
    const [showSection, setShowSection] = useState(transactionsTable)
    const [transactions, setTransactions] = useState<Payment[]>([]);
    const [submissions, setSubmissions] = useState<SubmissionType[]>([]);
    const [selectedTransaction, setSelectedTransaction] = useState<Payment | null>(null);
    const [selectedSubmission, setSelectedSubmission] = useState<SubmissionType | null>(null);
    const [selectedSubmissionCard, setSelectedSubmissionCard] = useState<CardType | null>(null);
    const [markAsPaidLoading, setMarkAsPaidLoading] = useState(false)
    useEffect(() => {
        if (user) {
            getTransactions();
            getSubmissionsForUser();
        }
    }, [user])
    useEffect(() => {
        if (selectedSubmission) {
            getSelectedSubmissionParentCard()
        }
    }, [selectedSubmission])
    const getSelectedSubmissionParentCard = async () => {
        var selectedSubmissionscard = await getCard(selectedSubmission?.cardId!)
        setSelectedSubmissionCard(selectedSubmissionscard)
    }
    const getTransactions = async () => {
        var userTransactions = await getUserPayments(user?.id!)
        setTransactions(userTransactions)
    }
    const getSubmissionsForUser = async () => {
        var userSubmissions = await getSubmissions(user?.id!)
        setSubmissions(userSubmissions)
    }
    const handleChange = (event: SelectChangeEvent) => {
        setShowSection(event.target.value as string);
    };
    const markAsPaid = async (submission: SubmissionType) => {
        setMarkAsPaidLoading(true)
        submission!.paymentState = PaymentStateEnum.Paid
        await updateSubmission(submission!)
        setMarkAsPaidLoading(false)
    }
    const getPaymentState = (submission: SubmissionType) => {
        switch (submission.paymentState) {
            case PaymentStateEnum.Paid:
                return <span style={{ color: "green" }}>Paid</span>
            case PaymentStateEnum.Unpaid:
                return <span style={{ color: "red" }}>Unpaid</span>
            case PaymentStateEnum.Pending:
                return <span style={{ color: "purple" }}>Pending</span>
            default:
                return <span style={{ color: "grey" }}>Unknown</span>
        }
    }
    return (
        <Container style={{ backgroundColor: 'white' }}>
            <Row>
                <Col md={4} className='text-center p-2'>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Table Display</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={showSection}
                            label="tableDisplay"
                            onChange={handleChange}
                        >
                            <MenuItem value={transactionsTable}>Transactions</MenuItem>
                            <MenuItem value={submissionsTable}>Submissions</MenuItem>
                        </Select>
                    </FormControl>
                    {showSection == transactionsTable && <>
                        {!selectedTransaction || selectedTransaction.userId != user?.id ?
                            <div className='d-flex justify-content-center'>
                                <p>Please select a transaction</p>
                            </div> : (
                                <div>
                                    <h4>Transaction Order Number</h4>
                                    <p> # {selectedTransaction?.orderId}</p>
                                    <p><b>Bounty Price: </b>${selectedTransaction?.bountyPrice.toFixed(2)}</p>
                                    <p><b>Fee Paid: </b>${selectedTransaction?.fee.toFixed(2)}</p>
                                    <p><b>Status: </b>{selectedTransaction?.status}</p>
                                    <Button variant='outline-info'>Refund Payment</Button>
                                </div>
                            )}
                    </>}
                    {showSection == submissionsTable && <>
                        {!selectedSubmission || selectedSubmission.userId != user?.id ?
                            <div className='d-flex justify-content-center'>
                                <p>Please select a submission</p>
                            </div> : (
                                <div>
                                    <h4>Card Title:</h4>
                                    <p>{selectedSubmission?.cardTitle}</p>
                                    <small>guid: {selectedSubmission.cardGuid}</small>
                                    <p><b>Bounty Price: </b>${selectedSubmissionCard?.jobBounty}</p>
                                    {selectedSubmission.paymentState == PaymentStateEnum.Pending &&
                                        <Button style={{ backgroundColor: "green", border: "none" }}
                                            onClick={() => markAsPaid(selectedSubmission)}>{markAsPaidLoading ? <Spinner /> : <>Mark as Paid</>}</Button>}
                                </div>
                            )}
                    </>}
                </Col>
                <Col md={8}>
                    {showSection == transactionsTable && <>
                        <TableContainer className='p-2'>
                            <Table sx={{ backgroundColor: 'white' }}>
                                <TableHead sx={{ backgroundColor: '#808080' }}>
                                    <TableRow>
                                        <TableCell>Id</TableCell>
                                        <TableCell>Purchase Date</TableCell>
                                        <TableCell>Transaction Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactions?.map((transaction) => (
                                        <TableRow key={transaction.id} onClick={() => setSelectedTransaction(transaction)} style={{ cursor: 'pointer' }}>
                                            <TableCell>{transaction.id}</TableCell>
                                            <TableCell>{transaction.purchaseDate}</TableCell>
                                            <TableCell>{(transaction.bountyPrice + transaction.fee).toFixed(2)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>}
                    {showSection == submissionsTable && <>
                        <TableContainer className='p-2'>
                            <Table sx={{ backgroundColor: 'white' }}>
                                <TableHead sx={{ backgroundColor: '#808080' }}>
                                    <TableRow>
                                        <TableCell>Id</TableCell>
                                        <TableCell>Payment State</TableCell>
                                        <TableCell>Progress</TableCell>
                                        <TableCell>Is Accepted</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {submissions?.map((submission) => (
                                        <TableRow key={submission.id} onClick={() => setSelectedSubmission(submission)} style={{ cursor: 'pointer' }}>
                                            <TableCell>{submission.id}</TableCell>
                                            <TableCell>{getPaymentState(submission)}</TableCell>
                                            <TableCell>{submission.paymentState == PaymentStateEnum.Pending ? "Completed" : "In Progress"}</TableCell>
                                            <TableCell>{submission.isAccepted ? "Yes" : "No"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>}
                </Col>
            </Row>
        </Container>
    );
};

export default UserInformation;