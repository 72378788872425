import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CardType } from '../../types/CardType';
import Styles from '../../css/profile/dashboard.module.css'
import { Button, Col, Container, Row } from 'react-bootstrap';
import AcceptSubmissionModal from '../profile/AcceptSubmissionModal';
import { useContext, useState } from 'react';
import { SubmissionType } from '../../types/SubmissionType';
import RejectSubmissionModal from '../profile/RejectSubmissionModal';
import DeleteJobModal from '../profile/DeleteJobModal';
import { updateCard } from '../../services/cardService';
import { createBountyOrder, deleteCardAndAssociatedPayment } from '../../services/paymentsService';
import { PayPalOrderResponseType } from '../../types/PayPalOrderResponseType';
import CurrentUserContext from '../../client/UserContextProvider';
import { useNavigate } from 'react-router-dom';
interface CustomAccordionProps {
  card: CardType;
  removeCardFromList: (id: number) => void;
}
const CustomAccordion: React.FC<CustomAccordionProps> = ({ card, removeCardFromList }) => {
  var navigate = useNavigate()
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showDeleteJobModal, setShowDeleteJobModal] = useState(false)
  const [showAccept, setShowAccept] = useState(false)
  const [showReject, setShowReject] = useState(false)
  const [acceptedSubmission, setAcceptedSubmission] = useState<SubmissionType | null>()
  const [rejectedSubmission, setRejectedSubmission] = useState<SubmissionType | null>()
  const context = useContext(CurrentUserContext);
const { currentUser } = context!
  const handleChange = () => {
    if (!card.pendingPayment) {
      setExpanded(!expanded);
    }
  };
  const handleShowAccept = () => {
    setShowAccept(!showAccept)
  }
  const handleShowReject = () => {
    setShowReject(!showReject)
  }
  const handleShowDeleteJob = () => {
    setShowDeleteJobModal(!showDeleteJobModal)
  }
  const resolvePayment = async () => {
    var payPalOrderResponse: PayPalOrderResponseType = await createBountyOrder(0, card?.id!, currentUser?.id!)
    card!.tokenId = payPalOrderResponse.id
    await updateCard(card!)
    for (let i = 0; i < payPalOrderResponse.links.length; i++) {
      const link = payPalOrderResponse.links[i];
      if (link.rel == "payer-action") {
        window.open(link.href, '_blank');
      }
    }
  }
  return (
    <div>
      {showAccept && <AcceptSubmissionModal showModal={showAccept} onClose={handleShowAccept} submissionDetails={acceptedSubmission!} cardDetails={card} />}
      {showReject && <RejectSubmissionModal showModal={showReject} onClose={handleShowReject} submissionDetails={rejectedSubmission!} cardDetails={card} />}
      {showDeleteJobModal && <DeleteJobModal showModal={showDeleteJobModal} onClose={handleShowDeleteJob} id={card.id!} removeCardFromList={removeCardFromList} handleChange={handleChange} card={card} />}
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{ backgroundColor: "#2A2D34"}}
        >
          <Typography sx={{ width: '33%', maxWidth: "33%", flexShrink: 0, overflow: "hidden", textOverflow: "ellipsis", color: "white" }}>
            {card.jobTitle}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {!card.pendingPayment && !card.isArchived && <div style={{ display: "inline" }}>
              <p style={{ display: "inline" }}>{!card.acceptedSubmission ? <p style={{ display: "inline", color: "#009DDC" }}>In Progress</p> : <p style={{ display: "inline", color: "#009B72" }}>Submission Accepted</p>}</p>
              <p style={{ display: "inline" }}> • </p>
              <p style={{ display: "inline" }}>Submissions: {card.submissions.filter(x => !x.isRejected && !x.isAccepted).length}</p>
              <p style={{ display: "inline" }}> • </p>
              <p style={{ display: "inline", color: "#009B72" }}>Accepted: {card.acceptedSubmission ? 1 : 0}</p>
              <p style={{ display: "inline" }}> • </p>
              <p style={{ display: "inline", color: "red" }}>Rejected: {card.submissions.filter(x => x.isRejected).length}</p>
            </div>}
            {card.isArchived && <div style={{ display: "inline" }}>
              <p style={{ display: "inline", textAlign: "center" }}>Card is archived</p>
            </div>}
            {card.pendingPayment && <div style={{ display: "inline" }}>
              <p style={{ display: "inline" }}>Pending Payment  •  <Button style={{ background: "#009DDC", border: "none", display: "inline" }} onClick={resolvePayment}>Complete Payment</Button></p>
              <Button style={{ background: "green", border: "none", display: "inline", marginLeft: "5px" }} onClick={() => navigate(`/job/${card.id}`)}>View Job</Button>
              {!card.acceptedSubmission && <Button style={{ background: "red", border: "none", display: "inline", marginLeft: "5px" }} onClick={async () => {
                await deleteCardAndAssociatedPayment(card.id!)
                removeCardFromList(card.id!)
                }}>Cancel Job</Button>}
            </div>}

          </Typography>
        </AccordionSummary>
        {!card.pendingPayment && <AccordionDetails sx={{backgroundColor: "#2A2D34"}}>
          <Typography>
            {!card.isArchived && <div className={Styles.buttonWrapper}>
            {!card.acceptedSubmission && <Button style={{ margin: "1% 1% 1% 0", backgroundColor: "red", border: "none" }} onClick={() => handleShowDeleteJob()}>Cancel Job</Button>}
              <Button style={{ margin: "1% 1% 1% 0", background: "#009DDC", border: "none" }} onClick={() => navigate(`/job/${card.id}`)}>See Job</Button>

            </div>}
            {card.submissions.map((submission, index) => {
              if (!submission.isRejected) {
                return <div key={index} id={Styles.submission}>
                  <Container>
                    <Row>
                      <Col xs={8}>
                        <p>Card Title: {submission.cardTitle}</p>
                        <div className={Styles.submissionLinkWrapper}><p>Link: <a className={Styles.submissionLink} href={submission.link} target='_blank'>{submission.link}</a></p></div>
                      </Col>
                      <Col xs={1}>
                        {card.acceptedSubmission ? <p>ACCEPTED</p> :
                          <div><Button id={Styles.acceptBtn} onClick={() => {
                            setAcceptedSubmission(submission)
                            handleShowAccept()
                          }}>Accept</Button><br />
                            <div style={{ height: "20px" }} />
                            <Button id={Styles.rejectBtn} onClick={() => {
                              setRejectedSubmission(submission)
                              handleShowReject()
                            }
                            }>Reject</Button></div>}

                      </Col>
                    </Row>
                  </Container>
                  <hr style={{ borderColor: "grey" }} />
                </div>
              }
            })}

          </Typography>
        </AccordionDetails>}
      </Accordion>
    </div>
  );
}
export default CustomAccordion;