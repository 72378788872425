export type SubmissionType = {
  cardId: number,
  id?: number | null,
  link: string,
  userId: number,
  cardGuid: string
  isAccepted: boolean,
  isRejected: boolean,
  cardTitle: string,
  paymentState: PaymentStateEnum,
  changeRequestsRemaining: number,
  posterEmail: string
}
export enum PaymentStateEnum {
  Unpaid,
  Pending,
  Paid
}