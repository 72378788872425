import { createTheme } from "@mui/material";

const textFieldTheme = (theme: any) => createTheme({
    ...theme,
    components: {
        MuiIconButton: {
            styleOverrides: {
                sizeMedium: {
                    color: "#black"
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "white",
                    '&.Mui-focused': {
                        color: 'white',
                    },
                }
            }
        },
    }
})
export default textFieldTheme