import { Col, Container, Row } from "react-bootstrap";
import Styles from "../css/landing/landingPage.module.css"
import landingPagetext from "../Text/LandingPage"
import LandingPageLogo from "../images/LandingPageLogo.png"
import Step1 from "../images/Step1.png"
import Step2 from "../images/Step2.png"
import Step3 from "../images/Step3.png"

export default function LandingPage() {
    return (
        <Container style={{ paddingBottom: '60px' }}>
            <Row>
                <img className="mb-3" src={LandingPageLogo} alt="Logo" />
            </Row>
            <Row>
                <Col>
                    <div className={Styles.landingPageWrapper}>
                        <div style={{ textAlign: "center" }}>
                            <h3 style={{ fontSize: "30px" }}>Welcome to Build-My-Idea, where innovation meets opportunity!</h3>
                            {landingPagetext.introduction}
                        </div>
                    </div>
                    <div className="m-3 text-center">
                        <h1 style={{ color: 'white' }}>How It Works</h1>
                    </div>
                    <div className={Styles.landingPageWrapper}>

                        <Row className="text-center">
                            <Col className="p-3">
                            <img className="m-2" id={Styles.step1} src={Step1} alt="how it works graphic" />
                                <h3 className="mb-3">Step One</h3>
                                <h5>Post Job or Browse Job Cards</h5>
                                <p>
                                    Business owners initiate the process by posting detailed job listings outlining their requirements for new website projects. Meanwhile, developers peruse these listings, seeking projects that align with their expertise and spark their creativity.
                                </p>
                            </Col>
                            <Col className="p-3">
                            <img className="m-2" id={Styles.step2} src={Step2} alt="how it works graphic" />
                                <h3 className="mb-3">Step Two</h3>
                                <h5>Submit Work or Review Submissions</h5>
                                <p>
                                    Developers begin crafting high-quality websites that meet the specified job requirements. Once completed, they submit their work for review. Business owners then evaluate these submissions to identify the one that best meets their needs.
                                </p>
                            </Col>
                            <Col className="p-3">
                            <img className="m-2" id={Styles.step3} src={Step3} alt="how it works graphic" />
                                <h3 className="mb-3">Step Three</h3>
                                <h5>Approval and Payment Release</h5>
                                <p>
                                    Upon finding a satisfactory submission, the business owner approves the developer’s work. Subsequently, the payment held in escrow is released, ensuring a successful transaction. This results in a new website for the business owner and a well-deserved payment for the developer.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}