export enum TechnologyTags {
    AngularJS = "#F4BBE5",
    ApacheGroovy = "#8ACBCE",
    CPlusPlus = "#D6E88F",
    CSS = "#F8C69A",
    Dart = "#B9D1E8",
    Elixir = "#F8C97F",
    Golang = "#FFD7AC",
    Haskell = "#F1AAC9",
    HTML = "#C6EAB0",
    Java = "#A5E3B9",
    JavaScript = "#F3A8D7",
    Kotlin = "#FFBEB0",
    NodeJS = "#93E0B9",
    ObjectiveC = "#9AD6B2",
    Perl = "#D1EAA8",
    PHP = "#FFD17E",
    Python = "#FCA6A4",
    Ruby = "#B2E4F1",
    Rust = "#A8DDC9",
    Scala = "#F5C1D0",
    SQL = "#FFA3A0",
    Swift = "#FFBC8E",
    TypeScript = "#6ED1F7",
    VueJS = "#B5D5E3"
}


export enum WebFrameworkTags {
    Angular = "#60B98B",
    ApacheWicket = "#87B4D4",
    ASPDotNET = "#C6EAB0",
    ASPDotNETCORE = "#FFBC8E",
    ASPDotNETMVC = "#4BA6D1",
    Barracude = "#F073A4",
    Bootstrap = "#FFD7AC",
    CakePHP = "#DE4A8F",
    Catalyst = "#EC5F71",
    CherryPy = "#B9D1E8",
    CodeIgniter = "#B16AEC",
    Django = "#3A7ED6",
    ExpressJS = "#F9A44C",
    Flask = "#E56D67",
    Gatsby = "#4FC4F1",
    JHipster = "#F1AAC9",
    jQuery = "#8FBD67",
    Laminas = "#67C7B2",
    Laravel = "#43B4E3",
    Li3 = "#6BC1A4",
    Meteor = "#E74F5D",
    Mojolicious = "#5E8F3D",
    NextJS = "#A8DDC9",
    Padrino = "#93E0B9",
    PlayFramework = "#92CF6A",
    RubyOnRails = "#F49C4A",
    React = "#A3D9FF",
    Sinatra = "#AC75E5",
    SmartFrameworkPHP = "#E8449D",
    Symfony = "#D27332",
    Svelte = "#75A84B",
    web2py = "#8DC76E",
    Wt = "#F47D59",
    Yii = "#F6853B"
}
